import type {
  ProjectAnswer,
  ProjectAnswerCreateRequest,
  ProjectAnswerUpdateBatchRequest,
  ProjectAnswerUpdateRequest
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class ProjectAnswerResource {
  public static injectable = injectable(ProjectAnswerResource)

  async createProjectAnswer (projectId: string, data?: ProjectAnswerCreateRequest): Promise<ProjectAnswer> {
    const response = await axiosApi.post(`/project/${projectId}/answer`, data)
    return response.data as ProjectAnswer
  }

  async readProjectAnswer (projectId: string, id: string): Promise<ProjectAnswer> {
    const response = await axiosApi.get(`/project/${projectId}/answer/${id}`)
    return response.data as ProjectAnswer
  }

  async updateProjectAnswer (projectId: string, id: string, data: ProjectAnswerUpdateRequest): Promise<ProjectAnswer> {
    const response = await axiosApi.put(`/project/${projectId}/answer/${id}`, data)
    return response.data as ProjectAnswer
  }

  async deleteProjectAnswer (projectId: string, id: string): Promise<ProjectAnswer> {
    const response = await axiosApi.delete(`/project/${projectId}/answer/${id}`)
    return response.data as ProjectAnswer
  }

  async listProjectAnswers (projectId: string): Promise<ProjectAnswer[]> {
    const response = await axiosApi.get(`/project/${projectId}/answer`)
    return response.data as ProjectAnswer[]
  }

  async updateProjectAnswers (projectId: string, data: ProjectAnswerUpdateBatchRequest): Promise<ProjectAnswer[]> {
    const response = await axiosApi.put(`/project/${projectId}/answer`, data)
    return response.data as ProjectAnswer[]
  }

  async deleteProjectAnswers (projectId: string): Promise<ProjectAnswer[]> {
    const response = await axiosApi.delete(`/project/${projectId}/answer`)
    return response.data as ProjectAnswer[]
  }
}
