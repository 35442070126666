import type { ProjectAnswer } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectAnswerResource } from '../resources/project-answer'
import { refDefault } from '@vueuse/core'

export interface UseProjectAnswers {
  projectAnswersOptional: Ref<ProjectAnswer[] | undefined>
  projectAnswers: Ref<ProjectAnswer[]>
  listProjectAnswers: UseAsyncRunner<ProjectAnswer[]>
  updateProjectAnswers: UseAsyncRunner<ProjectAnswer[], [ProjectAnswer[] | []]>
  deleteProjectAnswers: UseAsyncRunner<ProjectAnswer[]>
}

export interface UseProjectAnswersOptions {
  resource: ProjectAnswerResource
  projectId: Ref<string>
}

export function useProjectAnswers (options: UseProjectAnswersOptions): UseProjectAnswers {
  const { projectId } = options

  const resource = options.resource
  const projectAnswersOptional = ref<ProjectAnswer[]>()
  const projectAnswers = refDefault(projectAnswersOptional, [])

  const listProjectAnswers = useAsyncRunner(async () => await resource.listProjectAnswers(projectId.value), projectAnswersOptional)

  const updateProjectAnswers = useAsyncRunner(async (answers?: ProjectAnswer[]) => await resource.updateProjectAnswers(projectId.value, answers ?? projectAnswers.value), projectAnswersOptional)

  const deleteProjectAnswers = useAsyncRunner(async () => await resource.deleteProjectAnswers(projectId.value), projectAnswersOptional)

  return {
    projectAnswersOptional,
    projectAnswers,
    listProjectAnswers,
    updateProjectAnswers,
    deleteProjectAnswers
  }
}
