import { injectable, injectRequired } from '../../../di'
import { ProjectComponentResource } from '../resources/project-component'
import type { UseProjectWorkflow } from '../use/project-workflow'
import { useProjectWorkflow } from '../use/project-workflow'
import type { Workflow } from 'engine.carbon-saver'

export class ProjectWorkflowState {
  public static injectable = injectable(ProjectWorkflowState)
  public readonly use: UseProjectWorkflow

  constructor () {
    this.use = useProjectWorkflow({
      resource: injectRequired(ProjectComponentResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectWorkflowState?: Workflow }): this {
    if (data?.projectWorkflowState !== undefined) {
      this.use.projectWorkflow.value = data.projectWorkflowState
    }
    return this
  }
}
