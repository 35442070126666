import { ProjectResource } from './resources/project'
import type { Ref } from 'vue'
import { ProjectsState } from './states/projects.js'
import { ProjectState } from './states/project'
import { ProjectAnswersState } from './states/project-answers'
import { ProjectAnswerResource } from './resources/project-answer'
import { ProjectActionsState } from './states/project-actions'
import { ProjectPublicShareState } from './states/project-public-share'
import { ProjectPublicShareResource } from './resources/project-public-share'
import { ProjectDefinitionState } from './states/project-definition'
import { ProjectDefinitionResource } from './resources/project-definition'
import { ProjectComponentResource } from './resources/project-component'
import { ProjectComponentsState } from './states/project-components'
import { UniverseResource } from './resources/universe'
import { UniverseScopesState } from './states/universe-scopes'
import { UniversesState } from './states/universes'
import { ProjectWorkflowState } from './states/project-workflow'
import { provide } from '../../di'
import { ProjectReportState } from './states/project-report'
import { ProjectMetadataState } from './states/project-metadata'
import { ProjectWorkflowNavigationState } from './states/project-workflow-navigation'
import { provideModule as provideUserModule } from '../user/module.js'
import { ProjectBundleResource } from './resources/project-bundle'
import { ProjectBundleState } from './states/project-bundle'

export function provideModule (): void {
  provideUserModule()

  UniverseResource.injectable.provide()
  UniversesState.injectable.provide()

  ProjectResource.injectable.provide()
  ProjectBundleResource.injectable.provide()

  ProjectsState.injectable.provide()
  ProjectDefinitionResource.injectable.provide()
  ProjectComponentResource.injectable.provide()
  ProjectAnswerResource.injectable.provide()

  ProjectPublicShareResource.injectable.provide()
}

export function provideModuleProject (projectId: Ref<string>): void {
  provide('ref<projectId>', projectId)

  ProjectState.injectable.provide()
  ProjectBundleState.injectable.provide()
  ProjectActionsState.injectable.provide()
  ProjectDefinitionState.injectable.provide()
  ProjectAnswersState.injectable.provide()
  ProjectComponentsState.injectable.provide()
  ProjectWorkflowState.injectable.provide()
  ProjectWorkflowNavigationState.injectable.provide()
  ProjectMetadataState.injectable.provide()
  ProjectReportState.injectable.provide()

  ProjectPublicShareState.injectable.provide()
}

export function provideModuleUniverse (universeId: Ref<string>): void {
  provide('ref<universeId>', universeId)

  UniverseScopesState.injectable.provide()
}
