import { tl, tl_ } from '../../../plugins/i18next'

import type { OrganisationMember } from 'app-model.carbon-saver'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'
import type { OrganisationResource } from '../resources/organisation'

export interface UseOrganisation {
  organisationMembersOptional: Ref<OrganisationMember[] | undefined>
  organisationMembers: Ref<OrganisationMember[]>
  listOrganisationMembers: UseAsyncRunner<OrganisationMember[]>
}

export interface UseOrganisationOptions {
  resource: OrganisationResource
}

export function useOrganisation (options: UseOrganisationOptions): UseOrganisation {
  const { resource } = options

  const organisationMembersOptional = ref<OrganisationMember[]>()
  const organisationMembers = refDefault(organisationMembersOptional, [])

  const listOrganisationMembers = useAsyncRunner(
    async (): Promise<OrganisationMember[]> => {
      const organisationMembers = await resource.listOrganisationMembers('default')
      return organisationMembers
    },
    organisationMembersOptional)

  return {
    organisationMembersOptional,
    organisationMembers,
    listOrganisationMembers
  }
}

export function getRoleDisplayName (role: string): string {
  return tl_(`common.organisation-role.${role}`)
}

tl('common.organisation-role.administrator', 'administrateur')
tl('common.organisation-role.contributor', 'contributeur')
tl('common.organisation-role.guest', 'invité')
