import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'
import type { Universe, UniverseScopes } from 'app-model.carbon-saver'
import type { AdvicePageExposed } from 'engine.carbon-saver'

export class UniverseResource {
  public static injectable = injectable(UniverseResource)

  async listUniverses (): Promise<Universe[]> {
    const response = await axiosApi.get('/universe')
    return response.data as Universe[]
  }

  async readUniverseScopes (universeId: string): Promise<UniverseScopes> {
    const response = await axiosApi.get(`/universe/${universeId}/scopes`)
    return response.data as UniverseScopes
  }

  async readAdvicePage (universeId: string, advicePageId: string): Promise<AdvicePageExposed> {
    const response = await axiosApi.get(`/universe/${universeId}/advice-page/${advicePageId}`)
    return response.data as AdvicePageExposed
  }
}
