<template>
  <div class="atom-project-share-entry fill-height d-flex align-center">
    <atom-user-exposed-badge :small="small"
                             :value="value.user"
                             :disabled="value.user.disabled"
                             show-email>
    </atom-user-exposed-badge>
    <v-spacer></v-spacer>
    <atom-chip
      class="mx-2"
      v-if="author"
      :disabled="value.user.disabled"
      :small="!small"
      :x-small="small">
      {{ $tl('atom-project-share.author', 'Auteur') }}
    </atom-chip>
    <atom-chip
      class="mx-2"
      v-else-if="administrator"
      :disabled="value.user.disabled"
      :small="!small"
      :x-small="small">
      {{ $tl('atom-project-share.administrator', 'Administrateur') }}
    </atom-chip>
    <div>
      <v-tooltip left v-if="disabled">
        <template v-slot:activator="{ on, attrs }">
          <atom-chip v-bind="attrs"
                     v-on="on"
                     :disabled="value.user.disabled"
                     :color="roleColor"
                     :class="roleClass"
                     :small="!small"
                     :x-small="small">
            {{ roleLabel }}
          </atom-chip>
        </template>
        <span>{{ roleDescription }}</span>
      </v-tooltip>
      <v-menu v-else>
        <template v-slot:activator="{ on, attrs }">
          <atom-chip v-bind="attrs"
                     v-on="on"
                     :disabled="value.user.disabled"
                     :color="roleColor"
                     :class="roleClass"
                     :small="!small"
                     :x-small="small">
            {{ roleLabel }}
          </atom-chip>
        </template>
        <v-list>
          <template v-for="(role, $index) of availableShareRoles">
            <v-divider v-if="$index > 0" :key="`${role}-divider`"></v-divider>
            <v-list-item :key="role"
                         :disabled="isRoleDisabled(role)"
                         @click="handleRoleClick(role)">
              <v-list-item-content>
                <div class="d-flex align-center">
                  <div>
                    <v-list-item-title>
                    <span class="subtitle-1">
                      {{ getShareRoleLabel(role) }}
                    </span>
                    </v-list-item-title>
                    <span class="body-2">
                    {{ getShareRoleDescription(role) }}
                  </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div v-if="role === value.role">
                    <v-icon color="primary" :disabled="isRoleDisabled(role)">mdi-check</v-icon>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<style scoped lang="scss">
.atom-project-share {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import type { ProjectShare, ProjectShareRole } from 'app-model.carbon-saver'
import { availableShareRoles, getShareRoleDescription, getShareRoleLabel } from '../../use/project-share'
import AtomChip from '../../../../components/atoms/AtomChip.vue'
import { useVModels } from '@vueuse/core'
import AtomUserExposedBadge from '../../../user/components/atoms/AtomUserExposedBadge.vue'

export default defineComponent({
  name: 'AtomProjectShare',
  components: { AtomUserExposedBadge, AtomChip },
  props: {
    value: {
      type: Object as PropType<ProjectShare>,
      required: true
    },
    small: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    author: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    administrator: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    guest: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  setup: (props, { emit }) => {
    const { value: data } = useVModels(props, emit, { eventName: 'input' })

    const roleLabel = computed(() => {
      if (data.value.role === undefined) return undefined
      return getShareRoleLabel(data.value.role)
    })

    const roleDescription = computed(() => {
      if (data.value.role === undefined) return undefined
      return getShareRoleDescription(data.value.role)
    })

    const roleColor = computed(() => {
      if (data.value.user.disabled) return ''
      if (data.value.role === 'administrator') {
        return 'primary darken3'
      }

      if (data.value.role === 'guest') {
        return 'lighten5'
      }

      return 'primary lighten5'
    })

    const roleClass = computed(() => {
      if (data.value.user.disabled) return ''
      if (data.value.role === 'administrator') {
        return ''
      }

      if (data.value.role === 'guest') {
        return ''
      }

      return 'text--primary-darken3'
    })

    const handleRoleClick = (role: ProjectShareRole) => {
      data.value.role = role
    }

    function isRoleDisabled (role: ProjectShareRole): boolean {
      if (props.guest) {
        return role !== 'guest'
      }
      return false
    }

    return {
      roleLabel,
      roleDescription,
      roleClass,
      roleColor,
      isRoleDisabled,
      handleRoleClick,
      getShareRoleLabel,
      getShareRoleDescription,
      availableShareRoles
    }
  }
})
</script>
