import type { User } from './auth'
import { useAuth } from './auth'
import type { Ref } from 'vue'
import { computed } from 'vue'
import { useConfirmationDialog } from './confirmation-dialog'
import config from '../config'
import type { LicenseType } from 'app-model.carbon-saver'
import { licenseTypes } from 'app-model.carbon-saver'
import { router } from '../router'

export interface UseLicense {
  licenseType: Ref<LicenseType | undefined>
  showSubscriptionDialog: () => Promise<void>
}

export function getLicenseType (user?: User | null): LicenseType {
  if (config.tenant.tenantId !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return licenseTypes.at(-1)!
  }

  const reversedLicenseTypes = [...licenseTypes].reverse()

  if (user?.licenses !== undefined) {
    for (const licenseType of reversedLicenseTypes) {
      if (user.licenses.includes(licenseType)) {
        return licenseType
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return licenseTypes.at(0)!
}

const { confirmAction } = useConfirmationDialog()

export async function showSubscribeDialog () {
  return await confirmAction(async () => {
    router.push({ name: 'subscribe' })
  }, {
    width: 800,
    title: 'Passer au niveau supérieur',
    subtitle: 'Vous bénéficiez actuellement de la version limitée gratuite de Carbon Saver.',
    message: 'Si vous avez besoin de toutes les fonctionnalités disponibles et sans limitation de projet, vous pouvez souscrire un abonnement.',
    confirmLabel: 'Changer d\'offre',
    cancelLabel: 'Non, annuler'
  })
}

export function useLicense () {
  const { user } = useAuth()

  const licenseType = computed(() => {
    return getLicenseType(user.value)
  })

  return { licenseType }
}
