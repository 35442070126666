<template>
  <v-btn class="atom-button"
         v-bind="$attrs"
         v-on="$listeners"
         :class="{'d-none': (asyncRunner ? !asyncRunner.hasPermission.value : false)}"
         :disabled="$attrs.disabled || (asyncRunner ? !asyncRunner.hasPermission.value : false)"
         :loading="$attrs.loading || (asyncRunner ? asyncRunner.isLoading.value : false)"
         @click="asyncRunner && !asyncRunnerStateOnly ? asyncRunner.run() : () => {}">
    <v-icon :color="iconColor" :left="!$attrs.fab && !!$slots.default" v-if="icon"
            :small="$attrs.xSmall ? undefined : $attrs.small"
            :x-small="$attrs.xSmall">
      {{ icon }}
    </v-icon>
    <slot></slot>
    <v-icon :color="iconColor" :right="!$attrs.fab && !!$slots.default" v-if="iconRight"
            :small="$attrs.xSmall ? undefined : $attrs.small"
            :x-small="$attrs.xSmall">
      {{ iconRight }}
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { UseAsyncRunner } from '../../use/async-runner'

export default defineComponent({
  name: 'AtomButton',
  props: {
    icon: {
      type: String as PropType<string>
    },
    iconRight: {
      type: String as PropType<string>
    },
    iconColor: {
      type: String
    },
    asyncRunnerStateOnly: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    asyncRunner: {
      type: Object as PropType<UseAsyncRunner<any>>
    }
  }
})
</script>

<style lang="scss" scoped>
.atom-button {
  box-shadow: none;
}
</style>
