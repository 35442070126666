import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { ProjectWorkflowStepQuery } from './project-workflow.query.js'
import { filterSteps, findFirstStep, findSingleStep } from './project-workflow.query.js'
import type { UseProjectWorkflow } from './project-workflow'
import type { Step } from 'engine.carbon-saver'

export interface WorkflowProgress {
  count: number
  value: number,
  displayed: boolean,
  hasPrevious: boolean
  hasNext: boolean
  canPrevious: boolean
  canNext: boolean
}

export interface WorkflowPosition {
  index: number
  step: Step
}

export interface UseProjectWorkflowNavigation {
  progress: Ref<WorkflowProgress>
  current: Ref<WorkflowPosition | undefined>

  next: (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, currentIndex?: number, fallback?: () => void }) => boolean
  previous: (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, currentIndex?: number, fallback?: () => void }) => boolean
  goTo: (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, pickFirst?: boolean, fallback?: () => void }) => boolean
  goToIndex: (index: number, options?: { blank?: boolean, fallback?: () => void }) => boolean
  goToStep: (step: Step, options?: { blank?: boolean, fallback?: () => void }) => boolean

  options: Required<UseProjectWorkflowNavigationOptions>
}

export interface UseProjectWorkflowNavigationOptions {
  useProjectWorkflow: UseProjectWorkflow,
  progressStepPredicate?: (step: Step) => boolean
}

export function useProjectWorkflowNavigation (options: UseProjectWorkflowNavigationOptions): UseProjectWorkflowNavigation {
  const useProjectWorkflow = options.useProjectWorkflow

  const progressStepPredicate = options?.progressStepPredicate ?? (step => step.type === 'component' || step.type === 'add-component')

  const current = ref<WorkflowPosition | undefined>()

  const progress = computed<WorkflowProgress>(() => {
    const componentSteps = useProjectWorkflow.projectWorkflow.value.steps.filter(progressStepPredicate)
    const count = componentSteps.length

    const currentIndex = current.value?.index ?? 0
    const currentStep = current.value?.step

    let value = componentSteps.length - 1
    for (const step of useProjectWorkflow.projectWorkflow.value.steps.slice(currentIndex)) {
      if (progressStepPredicate(step)) {
        value = componentSteps.indexOf(step)
        break
      }
    }

    let canNext = true
    let hasNext = true
    if (currentStep?.type === 'component') {
      canNext = currentStep.canNext
      hasNext = true
    }

    return {
      count,
      value,
      displayed: currentStep !== undefined ? progressStepPredicate(currentStep) : false,
      canNext,
      hasNext,
      canPrevious: currentIndex > 0,
      hasPrevious: currentIndex > 0
    }
  })

  const goToStep = (step: Step, options?: { blank?: boolean, fallback?: () => void }): boolean => {
    const index = useProjectWorkflow.projectWorkflow.value.steps.indexOf(step)
    if (index < 0) {
      return false
    }
    if (options?.blank !== true) {
      current.value = {
        index,
        step
      }
    }
    return true
  }

  const goToIndex = (index: number, options?: { blank?: boolean, fallback?: () => void }): boolean => {
    const step = useProjectWorkflow.projectWorkflow.value.steps[index]
    if (step === undefined) {
      options?.fallback?.()
      return false
    }

    if (options?.blank !== true) {
      current.value = {
        index,
        step
      }
    }
    return true
  }

  const next = (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, currentIndex?: number, fallback?: () => void }): boolean => {
    const index = options?.currentIndex ?? current.value?.index
    if (index === undefined) {
      options?.fallback?.()
      return false
    }

    const nextSteps = useProjectWorkflow.projectWorkflow.value.steps.slice(index + 1)
    const nextStep = query ? findFirstStep(query, nextSteps) : nextSteps[0]

    if (nextStep === undefined) {
      options?.fallback?.()
      return false
    }
    return goToStep(nextStep, options)
  }

  const previous = (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, currentIndex?: number, fallback?: () => void }): boolean => {
    const index = options?.currentIndex ?? current.value?.index
    if (index === undefined) return false

    const previousSteps = useProjectWorkflow.projectWorkflow.value.steps.slice(0, index).reverse()
    const previousStep = query ? findFirstStep(query, previousSteps) : previousSteps[0]

    if (previousStep === undefined) {
      options?.fallback?.()
      return false
    }
    return goToStep(previousStep, options)
  }

  const goTo = (query?: ProjectWorkflowStepQuery, options?: { blank?: boolean, pickFirst?: boolean, fallback?: () => void }): boolean => {
    const steps = useProjectWorkflow.projectWorkflow.value.steps
    const step = query ? (options?.pickFirst ? filterSteps(query, steps)[0] : findSingleStep(query, steps)) : steps[0]
    if (step === undefined) {
      options?.fallback?.()
      return false
    }
    return goToStep(step, options)
  }

  return {
    current,
    progress,
    next,
    previous,
    goTo,
    goToStep,
    goToIndex,
    options: {
      useProjectWorkflow,
      progressStepPredicate
    }
  }
}
