export function tenantResolver (hostname: string, tenantSubdomainPrefix?: string): string | undefined {
  const subdomain = hostname.split('.')[0]

  if (tenantSubdomainPrefix !== undefined && subdomain.startsWith(tenantSubdomainPrefix)) {
    localStorage.removeItem('tenant')
    return subdomain.substring(tenantSubdomainPrefix.length)
  }

  return localStorage.getItem('tenant') ?? undefined
}

export function setTenant (tenant: string | undefined): void {
  tenant !== undefined ? localStorage.setItem('tenant', tenant) : localStorage.removeItem('tenant')
}
