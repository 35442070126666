<template>
  <v-card class="molecule-project-card">
    <v-card-title>
      <div class="d-flex flex-column">
        <h5 v-if="value.name">{{ value.name }}</h5>
        <h5 v-else>{{ $tl('organism-project-creation.text-field-placeholder', 'projet sans titre') }}</h5>
      </div>
      <v-spacer></v-spacer>
      <molecule-project-actions-button :project="value"
                                       :projects="enabledProjects"
                                       :disabled-projects="disabledProjects"
                                       :actions="cardActions"
                                       right>
      </molecule-project-actions-button>
    </v-card-title>
    <v-card-text>
      {{ renderedUpdatedAt }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <div class="d-flex flex-column">
        <div class="subtitle-1">
          {{ $tl('molecule-project-card.bat-impact', 'Bat\'impact') }}
        </div>
        <div class="d-flex flex-row pt-2 align-center">
          <atom-project-input-completion-chip small :value="value"></atom-project-input-completion-chip>
          <v-spacer></v-spacer>
          <atom-project-action-button :value="action" v-for="(action, $index) of cardMainActions"
                                      outlined
                                      color="primary"
                                      :key="$index">
          </atom-project-action-button>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.molecule-project-card {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, toRef } from 'vue'
import type { Project } from 'app-model.carbon-saver'
import MoleculeProjectActionsButton from '../molecules/MoleculeProjectActionsButton.vue'
import { injectRequired } from '../../../../di'
import { ProjectState } from '../../states/project'
import { ProjectDefinitionState } from '../../states/project-definition'
import * as projectModule from '../../module'
import { useProjectOpenUx, useProjectUx } from '../../use/project-ux'
import { useProjectDisplay } from '../../use/project-display'
import { ProjectsState } from '../../states/projects'
import AtomProjectInputCompletionChip from '../atoms/AtomProjectInputCompletionChip.vue'
import { ProjectComponentsState } from '../../states/project-components'
import { ProjectActionsState } from '../../states/project-actions'
import AtomProjectActionButton from '../atoms/AtomProjectActionButton.vue'

export default defineComponent({
  name: 'MoleculeProjectCard',
  components: { AtomProjectActionButton, AtomProjectInputCompletionChip, MoleculeProjectActionsButton },
  props: {
    value: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  setup: (props) => {
    const valueData = toRef(props, 'value')
    const projectId = computed(() => valueData.value.id)

    projectModule.provideModuleProject(projectId)

    const { cardActions, cardMainActions } = injectRequired(ProjectActionsState).use

    const projectUx = useProjectUx({
      useProject: injectRequired(ProjectState).withData({ project: valueData.value }).use
    })

    const projectOpenUX = useProjectOpenUx({
      useProject: injectRequired(ProjectState).withData({ project: valueData.value }).use,
      useProjectDefinition: injectRequired(ProjectDefinitionState).use,
      useProjectComponents: injectRequired(ProjectComponentsState).use
    })

    const projectDisplay = useProjectDisplay({
      project: valueData
    })

    const { projects: enabledProjects } = injectRequired(ProjectsState).use
    const { projects: disabledProjects } = injectRequired(ProjectsState).useDisabled

    return {
      enabledProjects,
      disabledProjects,
      cardActions,
      cardMainActions,
      ...projectDisplay,
      ...projectUx,
      ...projectOpenUX
    }
  }
})
</script>
