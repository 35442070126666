import type {
  ProjectPublicShare,
  ProjectPublicShareCreateRequest,
  ProjectPublicShareData
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { useAxios } from '../../../use/axios'
import config from '../../../config'

export class ProjectPublicShareResource {
  public static injectable = injectable(ProjectPublicShareResource)
  private axiosNoToken: AxiosInstance

  constructor () {
    this.axiosNoToken = useAxios({ config: config.api, tenantHeader: true })
  }

  async createProjectPublicShare (projectId: string, data?: ProjectPublicShareCreateRequest): Promise<ProjectPublicShareResource> {
    const response = await axiosApi.post(`/project/${projectId}/public-share`, data)
    return response.data as ProjectPublicShareResource
  }

  async readProjectPublicShare (projectId: string): Promise<ProjectPublicShare> {
    const response = await axiosApi.get(`/project/${projectId}/public-share`)
    return response.data as ProjectPublicShare
  }

  async readOrCreateProjectPublicShare (projectId: string, data?: ProjectPublicShareCreateRequest): Promise<ProjectPublicShare> {
    let response: AxiosResponse
    try {
      response = await axiosApi.get(`/project/${projectId}/public-share`)
    } catch (e: unknown) {
      const axiosError = e as AxiosError
      if (!axiosError.isAxiosError) {
        throw e
      }

      if (axiosError.response?.status === 404) {
        response = await axiosApi.post(`/project/${projectId}/public-share`, data)
      } else {
        throw e
      }
    }
    return response.data as ProjectPublicShare
  }

  async deleteProjectPublicShare (projectId: string): Promise<ProjectPublicShare> {
    const response = await axiosApi.delete(`/project/${projectId}/public-share`)
    return response.data as ProjectPublicShare
  }

  async readProjectPublicShareData (projectId: string): Promise<ProjectPublicShareData> {
    const response = await this.axiosNoToken.get(`/project/${projectId}/public-share/data`)
    return response.data as ProjectPublicShareData
  }
}
