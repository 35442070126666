import type {
  Project,
  ProjectCreateRequest,
  ProjectDisabledUpdateRequest,
  ProjectListQuery,
  ProjectShare,
  ProjectSharePost,
  ProjectUpdateRequest
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class ProjectResource {
  public static injectable = injectable(ProjectResource)

  async listProjects (query?: ProjectListQuery): Promise<Project[]> {
    const response = await axiosApi.get('/project', {
      params: {
        disabled: query?.disabled
      }
    })
    return response.data as Project[]
  }

  async readProject (id: string): Promise<Project> {
    const response = await axiosApi.get(`/project/${id}`)
    return response.data as Project
  }

  async createProject (project: ProjectCreateRequest): Promise<Project> {
    const response = await axiosApi.post('/project', project)
    return response.data as Project
  }

  async updateProject (id: string, project: ProjectUpdateRequest): Promise<Project> {
    const response = await axiosApi.put(`/project/${id}`, project)
    return response.data as Project
  }

  async updateProjectDisabled (id: string, data: ProjectDisabledUpdateRequest): Promise<Project> {
    const response = await axiosApi.put(`/project/${id}/disabled`, data)
    return response.data as Project
  }

  async deleteProject (id: string): Promise<Project> {
    const response = await axiosApi.delete(`/project/${id}`)
    return response.data as Project
  }

  async duplicateProject (id: string): Promise<Project> {
    const response = await axiosApi.post(`/project/${id}/duplicate`)
    return response.data as Project
  }

  async updateProjectShares (id: string, shares: ProjectSharePost[]): Promise<ProjectShare[]> {
    const response = await axiosApi.put(`/project/${id}/share`, shares)
    return response.data as ProjectShare[]
  }
}
