import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectComponentResource, ReadProjectReportOptions } from '../resources/project-component'
import { refDefault } from '@vueuse/core'
import type { ComponentOutput, FinalScoreItem, TagExposed, Workflow } from 'engine.carbon-saver'
import { useDebug } from '../../../use/debug'

export interface UseProjectWorkflow {
  projectWorkflowOptional: Ref<Workflow | undefined>
  projectWorkflow: Ref<Workflow>
  readProjectWorkflow: UseAsyncRunner<Workflow, [options?: { tags?: Required<Pick<TagExposed, 'key' | 'value'>>[], componentId?: string, report?: boolean }]>
  lastScoreOutput: Ref<ComponentOutput<FinalScoreItem> | undefined>
  options: Required<UseProjectWorkflowOptions>
}

export interface UseProjectWorkflowOptions {
  resource: ProjectComponentResource
  projectId: Ref<string>
}

export function useProjectWorkflow (options: UseProjectWorkflowOptions): UseProjectWorkflow {
  const { projectId } = options

  const resource = options.resource
  const projectWorkflowOptional = ref<Workflow>()
  const projectWorkflow = refDefault(projectWorkflowOptional, {
    steps: [],
    rootComponent: {
      id: '',
      model: {
        id: ''
      }
    }
  })

  const { debug } = useDebug()

  const readProjectWorkflow = useAsyncRunner(async (options?: { tags?: Required<Pick<TagExposed, 'key' | 'value'>>[], componentId?: string, report?: boolean }) => {
    let effectiveOptions: ReadProjectReportOptions | undefined = options
    if (debug.value) {
      effectiveOptions = { ...options, debug: true }
    }

    return await resource.readProjectWorkflow(projectId.value, effectiveOptions)
  }, projectWorkflowOptional)

  const lastScoreOutput = ref()

  return {
    projectWorkflowOptional,
    projectWorkflow,
    readProjectWorkflow,
    lastScoreOutput,
    options: {
      resource,
      projectId
    }
  }
}
