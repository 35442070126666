<template>
  <v-container class="page-internal-settings">
    <layout-responsive-row>
      <h3 class="pt-4">{{ $tl('page-advanced-settings.title', 'Paramètres avancés') }}</h3>
    </layout-responsive-row>
    <layout-responsive-row>
      <h5>{{ $tl('page-advanced-settings.debug.title', 'Déboguage') }}</h5>
      <v-switch v-model="debug"
                :label="$tl('page-advanced-settings.debug.subtitle', 'Afficher les informations de déboguage')"/>
    </layout-responsive-row>
  </v-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useDebug } from '../../use/debug'
import LayoutResponsiveRow from '../layout/LayoutResponsiveRow.vue'

export default defineComponent({
  name: 'PageAdvancedSettings',
  components: { LayoutResponsiveRow },
  setup () {
    const { debug } = useDebug()

    return { debug }
  }
})
</script>
