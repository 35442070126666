import type { NavigationGuardNext, Route } from 'vue-router'
import type { AxiosOptions } from './axios'
import { useAxios } from './axios'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { UseAuth, User, UserPermissions } from './auth'
import { useLocalStorage, useSessionStorage } from '@vueuse/core'
import { getLicenseType, showSubscribeDialog } from './license'
import type { RouteMeta } from '../router'
import type { AppAbility, UserRole } from 'app-model.carbon-saver'
import { CaslAbilityFactory } from 'app-model.carbon-saver'
import config from '../config'

export class MockPermissions implements UserPermissions {
  hasPermission (): boolean {
    return true
  }

  hasPermissionRef (): Ref<boolean> {
    return ref(true)
  }
}

function encodeMockJwt (payload: any): string {
  const encodedHeaders = btoa(JSON.stringify({}))
  const encodedPayload = btoa(JSON.stringify(payload))
  const encodedSignature = btoa('mock')

  return `${encodedHeaders}.${encodedPayload}.${encodedSignature}`
}

let singleton: UseAuth | undefined

function useAuthMockFactory (): UseAuth {
  const permissions = new MockPermissions()
  const caslAbilityFactory = new CaslAbilityFactory()

  const meId = useSessionStorage<string>('user.me.id', '', { writeDefaults: false })

  const initOnce = async (): Promise<void> => {
    const axios = useAxios(axiosOptions({ config: config.api, tenantHeader: true }))
    if (!meId.value) {
      await axios.get('/user/me/id').then((response) => {
        const responseData = response.data as { id: string }
        meId.value = responseData.id
      })
    }
  }

  const navigationGuard = async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
    const meta = to.meta as RouteMeta | undefined

    const permissionGuard = meta?.checkPermissions?.(permissions)
    if (permissionGuard === false) {
      if (from.name === null) {
        return next({ name: 'home' })
      } else {
        return next(false)
      }
    }

    const license = getLicenseType(user.value)
    const licenseGuard = meta?.checkLicense?.(license)
    if (licenseGuard === false) {
      try {
        await showSubscribeDialog()
        return
      } finally {
        if (from.name === null) {
          next({ name: 'home' })
        } else {
          next(false)
        }
      }
    }

    return next()
  }

  const user = useLocalStorage<User & { id: string, roles: UserRole[] }>('mocks.auth.user', {
    id: '14539c3e-f851-476e-8f4b-c196eb7290dc',
    roles: [],
    givenName: 'Dev',
    familyName: 'User',
    email: 'dev.user@carbon-saver.test',
    language: 'fr',
    licenses: ['enterprise'],
    subscriptions: ['enterprise']
  })

  const ability: Ref<AppAbility | null> = computed(() => {
    if (!user.value) return null
    return caslAbilityFactory.forUser({
      id: meId.value,
      roles: user.value.roles
    })
  })

  const token = computed(() => {
    return encodeMockJwt({
      sub: user.value.id,
      given_name: user.value.givenName,
      email: user.value.email,
      family_name: user.value.familyName,
      language: user.value.language,
      licenses: user.value.licenses,
      subscriptions: user.value.subscriptions
    })
  })

  const axiosOptions = (options: AxiosOptions): AxiosOptions => {
    return {
      token,
      ...options
    }
  }

  const refresh = async (): Promise<void> => {
    // Do nothing
  }

  const logout = async (): Promise<void> => {
    meId.value = undefined
  }

  return {
    initOnce,
    navigationGuard,
    axiosOptions,
    hasPermission: permissions.hasPermission.bind(permissions),
    hasPermissionRef: permissions.hasPermissionRef.bind(permissions),
    user: computed(() => user.value),
    ability,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    refresh,
    logout
  }
}

export function useAuthMock (): UseAuth {
  if (singleton === undefined) {
    singleton = useAuthMockFactory()
  }
  return singleton
}
