import type { Universe } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { refDefault } from '@vueuse/core'
import type { UniverseResource } from '../resources/universe'

export interface UseUniverses {
  universesOptional: Ref<Universe[] | undefined>
  universes: Ref<Universe[]>
  listUniverses: UseAsyncRunner<Universe[]>
}

export interface UseUniversesOptions {
  resource: UniverseResource
}

export function useUniverses (options: UseUniversesOptions): UseUniverses {
  const resource = options.resource

  const universesOptional = ref<Universe[]>()
  const universes = refDefault(universesOptional, [])

  const listUniverses = useAsyncRunner(async () => await resource.listUniverses(), universesOptional)

  return {
    universesOptional,
    universes,
    listUniverses
  }
}
