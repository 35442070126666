<template>
  <v-alert class="atom-subscribe-alert" rounded="lg" style="color: #F28700" color="#FBEDDD">
    <div class="d-flex align-center">
      <v-icon left color="#F28700">mdi-alert-circle-outline</v-icon>
      <div class="flex-grow-1">
        <slot></slot>
      </div>
      <router-link :to="{name: 'subscribe'}">
        <a style="color: #F28700"
           class="flex-grow-0 font-weight-bold"> {{ $tl('atom-subscribe-alert.subscribe', 'changer d\'offre') }} </a>
      </router-link>

    </div>
  </v-alert>
</template>

<style lang="scss" scoped>
</style>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AtomSubscribeAlert'
})
</script>
