<template>
  <v-list class="molecule-project-actions-list">
    <template v-for="([, actions], $groupIndex) of actionGroups">
      <v-divider v-if="$groupIndex > 0" :key="`${$groupIndex}.divider`"></v-divider>
      <atom-project-action-button list-item :value="action" v-for="(action, $index) of actions"
                                  :right="right"
                                  :key="`${$groupIndex}.${$index}`">
      </atom-project-action-button>
    </template>
  </v-list>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import AtomProjectActionButton from '../atoms/AtomProjectActionButton.vue'
import type { ProjectAction } from '../../use/project-actions'

export default defineComponent({
  name: 'MoleculeProjectActionsList',
  components: { AtomProjectActionButton },
  props: {
    value: {
      type: Array as PropType<ProjectAction[]>,
      required: true
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const actionGroups = computed(() => {
      const groupsMap: Map<string | undefined, ProjectAction[]> = new Map()

      for (const action of props.value) {
        let actionGroup = groupsMap.get(action.group)
        if (actionGroup === undefined) {
          actionGroup = []
          groupsMap.set(action.group, actionGroup)
        }
        actionGroup.push(action)
      }

      return [...groupsMap.entries()]
    })

    return {
      actionGroups
    }
  }
})
</script>
