import { injectable, injectRequired } from '../../../di'
import { ProjectComponentResource } from '../resources/project-component'
import type { Report } from 'engine.carbon-saver'
import type { UseProjectReport } from '../use/project-report'
import { useProjectReport } from '../use/project-report'

export class ProjectReportState {
  public static injectable = injectable(ProjectReportState)
  public readonly use: UseProjectReport

  constructor () {
    this.use = useProjectReport({
      resource: injectRequired(ProjectComponentResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectReportState?: Report }): this {
    if (data?.projectReportState !== undefined) {
      this.use.projectReport.value = data.projectReportState
    }
    return this
  }
}
