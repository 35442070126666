import { injectable, injectRequired } from '../../../di'
import { ProjectDefinitionResource } from '../resources/project-definition'
import type { UseProjectDefinition } from '../use/project-definition'
import { useProjectDefinition } from '../use/project-definition'
import type { ProjectDefinition } from 'app-model.carbon-saver'

export class ProjectDefinitionState {
  public static injectable = injectable(ProjectDefinitionState)
  public readonly use: UseProjectDefinition

  constructor () {
    this.use = useProjectDefinition({
      resource: injectRequired(ProjectDefinitionResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectDefinition?: ProjectDefinition }): this {
    if (data?.projectDefinition !== undefined) {
      this.use.projectDefinition.value = data.projectDefinition
    }
    return this
  }
}
