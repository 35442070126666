import { injectable, injectRequired } from '../../../di'
import type { UseOrganisation } from '../use/organisation'
import { useOrganisation } from '../use/organisation'
import { OrganisationResource } from '../resources/organisation'

export class OrganisationState {
  public static injectable = injectable(OrganisationState)

  public readonly use: UseOrganisation

  constructor () {
    this.use = useOrganisation({
      resource: injectRequired(OrganisationResource)
    })
  }
}
