import type { Component, ComponentModelExposed, Step, StepType, TagExposed } from 'engine.carbon-saver'
import { intersectionWith, isArray } from 'lodash'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'

export interface ProjectComponentQuery {
  id?: Component['id']
  predicate?: (component: Component | undefined) => boolean
}

export interface ProjectTagsQuery {
  exact?: boolean
  keys?: string[]
  tags?: ProjectTagQuery[]
  predicate?: (tags: TagExposed[] | undefined) => boolean
}

export interface ProjectTagQuery {
  key: string
  value: string | number | boolean
}

export interface ProjectWorkflowStepTypeQuery {
  includes?: StepType | StepType[]
  excludes?: StepType | StepType[]
}

export interface ProjectComponentModelQuery {
  id?: ComponentModelExposed['id']
  predicate?: (component: ComponentModelExposed | undefined) => boolean
}

export interface ProjectWorkflowStepQuery {
  type?: ProjectWorkflowStepTypeQuery
  component?: ProjectComponentQuery
  parentComponent?: ProjectComponentQuery
  componentModel?: ProjectComponentModelQuery
  tags?: ProjectTagsQuery
  excludes?: ProjectWorkflowStepQuery[]
  inputRequired?: boolean
}

export function findFirstStep (stepQuery: ProjectWorkflowStepQuery, steps: Step[]): Step | undefined {
  const filteredSteps = filterSteps(stepQuery, steps)
  return filteredSteps[0]
}

export function findSingleStep (stepQuery: ProjectWorkflowStepQuery, steps: Step[]): Step | undefined {
  const filteredSteps = filterSteps(stepQuery, steps)
  if (filteredSteps.length > 1) {
    console.error({ stepQuery, filteredSteps })
    throw new Error('Many steps found for query')
  }
  return filteredSteps[0]
}

function safeComponent (step: Step): Component | undefined {
  if ((step.type === 'component' || step.type === 'add-component') && step.component !== undefined) {
    return step.component
  }
}

function safeTags (step: Step): TagExposed[] | undefined {
  if (step.type === 'component' || step.type === 'tags' || step.type === 'add-component') {
    return step.tags
  }
}

function safeComponentModel (step: Step): ComponentModelExposed | undefined {
  if (step.type === 'add-component') {
    return step.componentModel
  }
}

function safeParentComponent (step: Step): Component | undefined {
  if (step.type === 'component' || step.type === 'add-component') {
    return step.parentComponent
  }
}

export function stepToQuery (step: Step): ProjectWorkflowStepQuery {
  const query: ProjectWorkflowStepQuery = {
    type: {
      includes: step.type
    }
  }

  const component = safeComponent(step)

  if (component !== undefined) {
    query.component = {
      id: component.id
    }
  }

  const parentComponent = safeParentComponent(step)
  if (parentComponent !== undefined) {
    query.parentComponent = {
      id: parentComponent.id
    }
  }

  const tags = safeTags(step)

  if (tags !== undefined) {
    query.tags = {
      exact: true,
      tags: [...tags.map(tag => ({ key: tag.key, value: tag.value }))]
    }
  }

  const model = safeComponentModel(step)

  if (model !== undefined) {
    query.componentModel = {
      id: model.id
    }
  }

  return query
}

export function filterSteps (stepQuery: ProjectWorkflowStepQuery, steps: Step[]): Step[] {
  let filteredSteps: Step[] = [...steps]

  if (stepQuery.type !== undefined) {
    const stepTypeQuery = stepQuery.type

    if (stepTypeQuery.includes) {
      const includes = stepTypeQuery.includes
      filteredSteps = filteredSteps.filter(step => isArray(includes) ? includes.includes(step.type) : step.type === includes)
    }

    if (stepTypeQuery.excludes) {
      const excludes = stepTypeQuery.excludes
      filteredSteps = filteredSteps.filter(step => isArray(excludes) ? !excludes.includes(step.type) : step.type !== excludes)
    }
  }

  if (stepQuery.component !== undefined) {
    const componentId = stepQuery.component?.id
    if (componentId !== undefined) {
      filteredSteps = filteredSteps.filter(step => safeComponent(step)?.id === componentId)
    }
    const componentPredicate = stepQuery.component.predicate
    if (componentPredicate !== undefined) {
      filteredSteps = filteredSteps.filter(step => componentPredicate(safeComponent(step)))
    }
  }

  if (stepQuery.parentComponent !== undefined) {
    const parentComponentId = stepQuery.parentComponent?.id
    if (parentComponentId !== undefined) {
      filteredSteps = filteredSteps.filter(step => safeParentComponent(step)?.id === parentComponentId)
    }
    const componentPredicate = stepQuery.parentComponent.predicate
    if (componentPredicate !== undefined) {
      filteredSteps = filteredSteps.filter(step => componentPredicate(safeParentComponent(step)))
    }
  }

  if (stepQuery.tags !== undefined) {
    const tagsQuery = stepQuery.tags

    const tagsQueryPredicate = tagsQuery.predicate
    if (tagsQueryPredicate !== undefined) {
      filteredSteps = filteredSteps.filter(step => tagsQueryPredicate(safeTags(step)))
    }

    const tagsQueryKeys = tagsQuery.keys
    if (tagsQueryKeys !== undefined) {
      filteredSteps = filteredSteps.filter(step => {
        const queryTagKeys = new Set(tagsQuery.keys)
        const stepTagKeys = new Set(safeTags(step)?.map(tag => tag.key) ?? [])

        for (const stepTagKey of stepTagKeys) {
          if (queryTagKeys.delete(stepTagKey) && tagsQuery.exact !== true) {
            return true
          }
        }

        return [...queryTagKeys.values()].length === 0
      })
    }

    const tagsQueryTags = tagsQuery.tags
    if (tagsQueryTags !== undefined) {
      filteredSteps = filteredSteps.filter(step => {
        const stepTags = [...(safeTags(step) ?? [])]

        const stepTagsAsQueries: ProjectTagQuery[] = []

        for (const stepTag of stepTags) {
          stepTagsAsQueries.push({ key: stepTag.key, value: stepTag.value })
        }

        if (tagsQuery.exact) {
          return stepTagsAsQueries.length === tagsQueryTags.length && differenceWith(stepTagsAsQueries, tagsQueryTags, isEqual).length === 0
        }

        return intersectionWith(stepTagsAsQueries, tagsQueryTags, isEqual).length > 0
      })
    }
  }

  if (stepQuery.componentModel !== undefined) {
    const componentModelId = stepQuery.componentModel?.id
    if (componentModelId !== undefined) {
      filteredSteps = filteredSteps.filter(step => safeComponent(step)?.model?.id === componentModelId)
    }
    const componentModelPredicate = stepQuery.componentModel.predicate
    if (componentModelPredicate !== undefined) {
      filteredSteps = filteredSteps.filter(step => componentModelPredicate(safeComponent(step)))
    }
  }

  if (stepQuery.inputRequired) {
    const inputRequiredCandidates = filteredSteps.filter(s => {
      if (s.type !== 'component') return false
      return !s.canNext
    })

    if (inputRequiredCandidates.length > 0) {
      filteredSteps = inputRequiredCandidates
    }
  }

  return filteredSteps
}
