import { injectable, injectRequired } from '../../../di'
import { ProjectComponentResource } from '../resources/project-component'
import type { Workflow } from 'engine.carbon-saver'
import type { UseProjectMetadata } from '../use/project-metadata'
import { useProjectMetadata } from '../use/project-metadata'

export class ProjectMetadataState {
  public static injectable = injectable(ProjectMetadataState)
  public readonly use: UseProjectMetadata

  constructor () {
    this.use = useProjectMetadata({
      resource: injectRequired(ProjectComponentResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectMetadataState?: Workflow }): this {
    if (data?.projectMetadataState !== undefined) {
      this.use.projectMetadata.value = data.projectMetadataState
    }
    return this
  }
}
