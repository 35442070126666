import { injectable, injectRequired } from '../../../di'
import type { UseUniverses } from '../use/universes'
import { useUniverses } from '../use/universes'
import { UniverseResource } from '../resources/universe'

export class UniversesState {
  public static injectable = injectable(UniversesState)
  public readonly use: UseUniverses

  constructor () {
    this.use = useUniverses({
      resource: injectRequired(UniverseResource)
    })
  }
}
