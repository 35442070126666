import { injectable, injectRequired } from '../../../di'
import type { UseProjectActions } from '../use/project-actions'
import { useProjectActions } from '../use/project-actions'
import { ProjectState } from './project'
import { ProjectComponentResource } from '../resources/project-component'
import { ProjectDefinitionResource } from '../resources/project-definition'
import { ProjectBundleResource } from '../resources/project-bundle'

export class ProjectActionsState {
  public static injectable = injectable(ProjectActionsState)
  public readonly use: UseProjectActions

  constructor () {
    const useProject = injectRequired(ProjectState).use
    const projectComponentResource = injectRequired(ProjectComponentResource)
    const projectDefinitionResource = injectRequired(ProjectDefinitionResource)
    const projectBundleResource = injectRequired(ProjectBundleResource)

    this.use = useProjectActions({
      useProject,
      projectComponentResource,
      projectDefinitionResource,
      projectBundleResource
    })
  }
}
