import type { ProjectPublicShare, ProjectPublicShareCreateRequest, ProjectPublicShareData } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectPublicShareResource } from '../resources/project-public-share'
import { refDefault } from '@vueuse/core'

export interface UseProjectPublicShare {
  projectId: Ref<string>

  projectPublicShareOptional: Ref<ProjectPublicShare | undefined>
  projectPublicShare: Ref<ProjectPublicShare>

  readProjectPublicShare: UseAsyncRunner<ProjectPublicShare>
  readOrCreateProjectPublicShare: UseAsyncRunner<ProjectPublicShare, [ProjectPublicShareCreateRequest] | []>
  createProjectPublicShare: UseAsyncRunner<ProjectPublicShare, [ProjectPublicShareCreateRequest] | []>
  deleteProjectPublicShare: UseAsyncRunner<ProjectPublicShare>

  readProjectPublicShareData: UseAsyncRunner<ProjectPublicShareData>
}

export interface UseProjectPublicShareOptions {
  resource: ProjectPublicShareResource
  projectId: Ref<string>
}

export function useProjectPublicShare (options: UseProjectPublicShareOptions): UseProjectPublicShare {
  const resource = options.resource
  const projectId = options.projectId

  const projectPublicShareOptional = ref<ProjectPublicShare>()
  const projectPublicShare = refDefault(projectPublicShareOptional, {})

  const readProjectPublicShare = useAsyncRunner(async () => await resource.readProjectPublicShare(projectId.value), projectPublicShare)

  const readOrCreateProjectPublicShare = useAsyncRunner(async (arg?: ProjectPublicShareCreateRequest) => {
    const data: ProjectPublicShareCreateRequest = arg ?? {
      ...projectPublicShare.value
    }

    return await resource.readOrCreateProjectPublicShare(projectId.value, data)
  }, projectPublicShareOptional)

  const createProjectPublicShare = useAsyncRunner(async (arg?: ProjectPublicShareCreateRequest) => {
    const data: ProjectPublicShareCreateRequest = arg ?? {
      ...projectPublicShare.value
    }

    return await resource.createProjectPublicShare(projectId.value, data)
  }, projectPublicShareOptional)

  const deleteProjectPublicShare = useAsyncRunner(async () => await resource.deleteProjectPublicShare(projectId.value), projectPublicShareOptional)

  const readProjectPublicShareData = useAsyncRunner(async () => await resource.readProjectPublicShareData(projectId.value))

  return {
    projectPublicShareOptional,
    projectPublicShare,
    projectId,
    readProjectPublicShare,
    readOrCreateProjectPublicShare,
    createProjectPublicShare,
    deleteProjectPublicShare,
    readProjectPublicShareData
  }
}
