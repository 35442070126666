import { injectable, injectRequired } from '../../../di'
import type { Workflow } from 'engine.carbon-saver'
import type { UseProjectWorkflowNavigation } from '../use/project-workflow-navigation'
import { useProjectWorkflowNavigation } from '../use/project-workflow-navigation'
import { ProjectWorkflowState } from './project-workflow'

export class ProjectWorkflowNavigationState {
  public static injectable = injectable(ProjectWorkflowNavigationState)
  public readonly use: UseProjectWorkflowNavigation

  constructor () {
    this.use = useProjectWorkflowNavigation({
      useProjectWorkflow: injectRequired(ProjectWorkflowState).use
    })
  }

  withData (data?: { projectWorkflowState?: Workflow }): this {
    if (data?.projectWorkflowState !== undefined) {
      this.use.options.useProjectWorkflow.projectWorkflow.value = data.projectWorkflowState
    }
    return this
  }
}
