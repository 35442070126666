import type { ProjectResource } from '../resources/project'
import type { Project } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { refDefault } from '@vueuse/core'

export interface UseProjects {
  projectsOptional: Ref<Project[] | undefined>
  projects: Ref<Project[]>

  listProjects: UseAsyncRunner<Project[]>
}

export interface UseProjectOptions {
  resource: ProjectResource
  disabled?: boolean
}

export function useProjects (options: UseProjectOptions): UseProjects {
  const { resource, disabled } = options

  const projectsOptional = ref<Project[]>()
  const projects = refDefault<Project[]>(projectsOptional, [])

  const listProjects = useAsyncRunner(async () => await resource.listProjects({ disabled }), projectsOptional)

  return {
    projectsOptional,
    projects,
    listProjects
  }
}
