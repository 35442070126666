import { injectable, injectRequired } from '../../../di'
import type { UseProjectComponents } from '../use/project-components'
import { useProjectComponents } from '../use/project-components'
import type { Component } from 'engine.carbon-saver'
import { ProjectComponentResource } from '../resources/project-component'

export class ProjectComponentsState {
  public static injectable = injectable(ProjectComponentsState)
  public readonly use: UseProjectComponents

  constructor () {
    this.use = useProjectComponents({
      resource: injectRequired(ProjectComponentResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectComponents?: Component[] }): this {
    if (data?.projectComponents !== undefined) {
      this.use.projectComponents.value = data.projectComponents
    }
    return this
  }
}
