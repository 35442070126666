import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ReadProjectReportOptions, ProjectComponentResource } from '../resources/project-component'
import { refDefault } from '@vueuse/core'
import type { ComponentOutput, FinalScoreItem, Report, TagExposed } from 'engine.carbon-saver'
import { useDebug } from '../../../use/debug'

export interface UseProjectReport {
  projectReportOptional: Ref<Report | undefined>
  projectReport: Ref<Report>
  readProjectReport: UseAsyncRunner<Report, [options?: { tags?: Required<Pick<TagExposed, 'key' | 'value'>>[], componentId?: string }]>
  lastScoreOutput: Ref<ComponentOutput<FinalScoreItem> | undefined>
  options: Required<UseProjectReportOptions>
}

export interface UseProjectReportOptions {
  resource: ProjectComponentResource
  projectId: Ref<string>
}

export function useProjectReport (options: UseProjectReportOptions): UseProjectReport {
  const { projectId } = options

  const resource = options.resource
  const projectReportOptional = ref<Report>()
  const projectReport = refDefault(projectReportOptional, {
    sections: [],
    rootComponent: {
      id: '',
      model: {
        id: ''
      }
    }
  })

  const { debug } = useDebug()

  const readProjectReport = useAsyncRunner(async (options?: { tags?: Required<Pick<TagExposed, 'key' | 'value'>>[], componentId?: string }) => {
    let effectiveOptions: ReadProjectReportOptions | undefined = options
    if (debug.value) {
      effectiveOptions = { ...options, debug: true }
    }

    return await resource.readProjectReport(projectId.value, effectiveOptions)
  }, projectReportOptional)

  const lastScoreOutput = ref()

  return {
    projectReportOptional,
    projectReport,
    readProjectReport,
    lastScoreOutput,
    options: {
      resource,
      projectId
    }
  }
}
