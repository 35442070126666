import type { AddComponentStep, ComponentStep, Step, StepType, TagExposed, TagsStep } from 'engine.carbon-saver'
import type { Location, Route } from 'vue-router'
import { isArray } from 'lodash'
import type { ProjectTagQuery, ProjectWorkflowStepQuery } from './project-workflow.query'
import type { ReadProjectWorkflowOptions } from '../resources/project-component'

interface RouterMapper<S extends Step = any> {
  buildProjectWorkflowStepQuery: (route: Route) => ProjectWorkflowStepQuery
  buildRouterLocation: (step: S) => Location
}

class RouterMapperRegistry {
  constructor (private readonly defaultMapper: RouterMapper) {
  }

  private readonly map: Map<StepType, RouterMapper> = new Map<StepType, RouterMapper>()

  setMapper (type: StepType, mapper: RouterMapper): void {
    this.map.set(type, mapper)
  }

  getMapper (type?: StepType): RouterMapper {
    return type == null ? this.defaultMapper : (this.map.get(type) ?? this.defaultMapper)
  }
}

class DefaultRouterMapper<S extends Step> implements RouterMapper<S> {
  constructor (protected readonly type: StepType, protected readonly routeName: string) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildRouterLocation (step: S): Location {
    const location: Location = {
      name: this.routeName
    }

    return location
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildProjectWorkflowStepQuery (route: Route): ProjectWorkflowStepQuery {
    const stepQuery: ProjectWorkflowStepQuery = {
      type: {
        includes: this.type
      }
    }

    return stepQuery
  }
}

class WorkflowComponentStepRouteMapper extends DefaultRouterMapper<ComponentStep> {
  buildProjectWorkflowStepQuery (route: Route): ProjectWorkflowStepQuery {
    const stepQuery = super.buildProjectWorkflowStepQuery(route)

    const componentId = route.params.componentId
    if (componentId != null) {
      stepQuery.component = { id: componentId }
    }

    return stepQuery
  }

  buildRouterLocation (step: ComponentStep): Location {
    const location = super.buildRouterLocation(step)

    const componentId = step.component.id
    location.params = { componentId }

    return location
  }
}

class WorkflowAddComponentStepRouteMapper extends DefaultRouterMapper<AddComponentStep> {
  buildProjectWorkflowStepQuery (route: Route): ProjectWorkflowStepQuery {
    const stepQuery = super.buildProjectWorkflowStepQuery(route)

    const componentModelId = route.params.componentModelId
    const parentComponentIdQuery = route.query.parentComponentId
    const parentComponentId = Array.isArray(parentComponentIdQuery) ? parentComponentIdQuery[0] : parentComponentIdQuery

    if (parentComponentId != null) {
      stepQuery.parentComponent = { id: parentComponentId }
    }
    if (componentModelId != null) {
      stepQuery.componentModel = { id: componentModelId }
    }

    return stepQuery
  }

  buildRouterLocation (step: AddComponentStep): Location {
    const location = super.buildRouterLocation(step)

    const componentModelId = step.componentModel.id
    const parentComponentId = step.parentComponent?.id

    location.params = { componentModelId }
    location.query = { parentComponentId }

    return location
  }
}

class WorkflowTagsStepRouterMapper extends DefaultRouterMapper<TagsStep> {
  buildProjectWorkflowStepQuery (route: Route): ProjectWorkflowStepQuery {
    const stepQuery = super.buildProjectWorkflowStepQuery(route)

    if (route.query !== undefined) {
      const tags: ProjectTagQuery[] = []

      for (const [key, value] of Object.entries(route.query)) {
        // _ prefix is used for tag filtering in report mode
        if (key.startsWith('_')) continue
        if (isArray(value)) {
          for (const item of value) {
            if (item !== null) {
              tags.push({
                key, value: item
              })
            }
          }
        } else if (value !== null) {
          tags.push({
            key, value
          })
        }
      }

      stepQuery.tags = { exact: true, tags }
    }

    return stepQuery
  }

  buildRouterLocation (step: TagsStep): Location {
    const location = super.buildRouterLocation(step)

    location.query = {}

    for (const tag of step.tags) {
      // TODO: Add safe serialization/deserialization of number and boolean tags
      location.query[tag.key] = '' + tag.value
    }

    return location
  }
}

const mapperRegistry = new RouterMapperRegistry({
  buildRouterLocation: () => {
    return {
      name: 'project-workflow-start'
    }
  },
  buildProjectWorkflowStepQuery: () => {
    return {
      type: {
        includes: 'start'
      }
    }
  }
})

mapperRegistry.setMapper('end', new DefaultRouterMapper('end', 'project-workflow-end'))
mapperRegistry.setMapper('start', new DefaultRouterMapper('start', 'project-workflow-start'))
mapperRegistry.setMapper('component', new WorkflowComponentStepRouteMapper('component', 'project-workflow-component'))
mapperRegistry.setMapper('add-component', new WorkflowAddComponentStepRouteMapper('add-component', 'project-workflow-add-component'))
mapperRegistry.setMapper('tags', new WorkflowTagsStepRouterMapper('tags', 'project-workflow-tags'))

export function buildRouterWorkflowStepQuery (type: StepType, route: Route): ProjectWorkflowStepQuery {
  return mapperRegistry.getMapper(type).buildProjectWorkflowStepQuery(route)
}

export function buildRouterLocation (step: Step | undefined): Location {
  return mapperRegistry.getMapper(step?.type).buildRouterLocation(step)
}

export function buildRouterTags (route: Route): Required<Pick<TagExposed, 'key' | 'value'>>[] | undefined {
  let tags: Required<Pick<TagExposed, 'key' | 'value'>>[] | undefined

  for (const [key, value] of Object.entries(route.query)) {
    if (key.startsWith('_') && !key.endsWith('_') && typeof value === 'string') {
      if (tags === undefined) {
        tags = []
      }

      const tagKey = key.substring(1)
      tags.push({ key: tagKey, value })
    }
  }

  return tags
}

export function buildRouterReport (route: Route): boolean | undefined {
  let report: boolean | undefined
  if (typeof route.query._report_ === 'string') {
    report = route.query._report_ === 'true'
  }

  return report
}

export function buildRouterReset (route: Route): boolean | undefined {
  let reset: boolean | undefined
  if (typeof route.query._reset_ === 'string') {
    reset = route.query._reset_ === 'true'
  }

  return reset
}

export function buildRouterComponentId (route: Route): string | undefined {
  let componentId: string | undefined
  if (typeof route.query._componentId_ === 'string') {
    componentId = route.query._componentId_
  }

  return componentId
}

export function alterRouterLocation (location: Location, options?: ReadProjectWorkflowOptions): Location {
  if (options?.tags && options.tags.length > 0) {
    if (location.query === undefined) {
      location.query = {}
    }
    for (const tag of options.tags) {
      const tagValue = isArray(tag.value) ? tag.value[0] : tag.value
      location.query[`_${tag.key}`] = tagValue
    }
  }

  if (options?.componentId !== undefined) {
    if (location.query === undefined) {
      location.query = {}
    }
    location.query._componentId_ = options.componentId
  }

  if (options?.report) {
    if (location.query === undefined) {
      location.query = {}
    }
    location.query._report_ = 'true'
  }

  if (options?.reset) {
    if (location.query === undefined) {
      location.query = {}
    }

    location.query._reset_ = 'true'
  }

  return location
}
