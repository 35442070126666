import type { ProjectResource } from '../resources/project'
import type {
  Project,
  ProjectCreateRequest,
  ProjectDisabledUpdateRequest,
  ProjectShare,
  ProjectSharePost,
  ProjectUpdateRequest
} from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { refDefault } from '@vueuse/core'
import { useCasl } from '../../../use/casl'

export interface UseProject {
  projectOptional: Ref<Project | undefined>
  project: Ref<Project>
  projectId: Ref<string>
  isProjectTransient: Ref<boolean>

  createProject: UseAsyncRunner<Project, [ProjectCreateRequest] | []>
  readProject: UseAsyncRunner<Project>
  updateProject: UseAsyncRunner<Project, [ProjectUpdateRequest] | []>
  updateProjectDisabled: UseAsyncRunner<Project, [ProjectDisabledUpdateRequest]>
  deleteProject: UseAsyncRunner<Project>
  duplicateProject: UseAsyncRunner<Project>

  updateProjectShares: UseAsyncRunner<ProjectShare[], [ProjectShare[]]>
}

export interface UseProjectOptions {
  resource: ProjectResource
  projects?: Ref<Project[] | undefined>
  disabledProjects?: Ref<Project[] | undefined>
  project?: Ref<Project | undefined>
  projectId?: Ref<string>
}

export function useProject (options: UseProjectOptions): UseProject {
  const { projects, disabledProjects } = options ?? {}

  const resource = options.resource
  const projectId = options.projectId ?? ref(options.project?.value?.id ?? '')

  const projectOptional = ref<Project | undefined>(options.project ? options.project.value : projects?.value?.find(p => p.id === projectId.value))
  const project = refDefault(projectOptional, {
    id: 'transient'
  })

  const isProjectTransient = computed(() => {
    return projectOptional.value === undefined
  })

  const createProject = useAsyncRunner(async (data?: ProjectCreateRequest) => {
    const args = data ?? {}
    const project = await resource.createProject(args)
    projectId.value = project.id

    if (projects?.value !== undefined) {
      projects.value?.unshift(project)
      projects.value = [...projects.value]
    }

    return project
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'create' } })

  const readProject = useAsyncRunner(async () => {
    const memoryProject = projects?.value?.find(p => p.id === projectId.value)
    if (memoryProject !== undefined) {
      return memoryProject
    }

    return await resource.readProject(projectId.value)
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'read' } })

  const updateProject = useAsyncRunner(async (arg?: ProjectUpdateRequest) => {
    const payload: ProjectUpdateRequest = arg ?? {
      name: projectOptional.value?.name
    }

    const returnValue = await resource.updateProject(projectId.value, payload)

    if (projects?.value !== undefined) {
      const index = projects.value.findIndex(project => project.id === returnValue.id)
      if (index > -1) {
        projects.value.splice(index, 1, returnValue)
        projects.value = [...projects.value]
      }
    }

    return returnValue
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'update' } })

  const updateProjectDisabled = useAsyncRunner(async (data: ProjectDisabledUpdateRequest) => {
    const returnValue = await resource.updateProjectDisabled(projectId.value, data)

    if (returnValue.disabled === true) {
      if (projects?.value !== undefined) {
        const index = projects.value.findIndex(project => project.id === returnValue.id)
        if (index > -1) {
          const removed = projects.value.splice(index, 1)
          projects.value = [...projects.value]
          if (disabledProjects?.value !== undefined) {
            disabledProjects?.value?.unshift(...removed)
            disabledProjects.value = [...disabledProjects.value]
          }
        }
      }
    } else {
      if (disabledProjects?.value !== undefined) {
        const index = disabledProjects.value.findIndex(project => project.id === returnValue.id)
        if (index > -1) {
          const removed = disabledProjects.value.splice(index, 1)
          disabledProjects.value = [...disabledProjects.value]
          if (projects?.value !== undefined) {
            projects?.value?.unshift(...removed)
            projects.value = [...projects.value]
          }
        }
      }
    }

    if (projects?.value !== undefined) {
      const index = projects.value.findIndex(project => project.id === returnValue.id)
      if (index > -1) {
        projects.value.splice(index, 1, returnValue)
        projects.value = [...projects.value]
      }
    }

    if (disabledProjects?.value !== undefined) {
      const index = disabledProjects.value.findIndex(project => project.id === returnValue.id)
      if (index > -1) {
        disabledProjects.value.splice(index, 1, returnValue)
        disabledProjects.value = [...disabledProjects.value]
      }
    }

    return returnValue
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'disable' } })

  const deleteProject = useAsyncRunner(async () => {
    const returnValue = await resource.deleteProject(projectId.value)

    if (projects?.value !== undefined) {
      const index = projects.value.findIndex(project => project.id === returnValue.id)
      if (index > -1) {
        projects.value.splice(index, 1)
        projects.value = [...projects.value]
      }
    }

    if (disabledProjects?.value !== undefined) {
      const index = disabledProjects.value.findIndex(project => project.id === returnValue.id)
      if (index > -1) {
        disabledProjects.value.splice(index, 1)
        disabledProjects.value = [...disabledProjects.value]
      }
    }

    return returnValue
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'delete' } })

  const duplicateProject = useAsyncRunner(async () => {
    const project = await resource.duplicateProject(projectId.value)
    if (projects?.value !== undefined) {
      projects.value?.unshift(project)
      projects.value = [...projects.value]
    }

    return project
  }, projectOptional, { hasPermissionSpec: { resource: 'project', scopes: 'create' } })

  const caslForProject = useCasl().forProject(projectOptional)

  const updateProjectShares = useAsyncRunner(async (shares: ProjectShare[]) => {
    const sharesPost: ProjectSharePost[] = shares.map(s => ({
      role: s.role,
      userId: s.user.id
    }))

    project.value.shares = await resource.updateProjectShares(projectId.value, sharesPost)
    return project.value.shares
  }, undefined, {
    hasPermission: caslForProject.can('share')
  })

  return {
    projectOptional,
    project,
    projectId,
    isProjectTransient,
    createProject,
    readProject,
    updateProject,
    updateProjectDisabled,
    deleteProject,
    duplicateProject,
    updateProjectShares
  }
}
