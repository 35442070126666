<template>
  <v-row class="layout-responsive-row" :v-bind="$attrs">
    <v-col v-bind="colAttrs">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'LayoutResponsiveRow',
  props: {
    large: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup: (props) => {
    const colAttrs = computed(() => {
      if (props.large) {
        return {
          cols: 12,
          sm: 10,
          offsetSm: 1,
          lg: 8,
          offsetLg: 2
        }
      } else {
        return {
          cols: 12,
          sm: 10,
          offsetSm: 1,
          md: 8,
          offsetMd: 2,
          lg: 6,
          offsetLg: 3
        }
      }
    })

    return {
      colAttrs
    }
  }
})
</script>

<style lang="scss">

</style>
