import type {
  DuplicateProjectDefinitionRequest,
  ProjectDefinition,
  ProjectDefinitionCreateRequest,
  ProjectDefinitionUpdateRequest
} from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectDefinitionResource } from '../resources/project-definition'
import { refDefault } from '@vueuse/core'

export interface UseProjectDefinition {
  projectDefinitionOptional: Ref<ProjectDefinition | undefined>
  projectDefinition: Ref<ProjectDefinition>
  projectId: Ref<string>

  readProjectDefinition: UseAsyncRunner<ProjectDefinition>
  readOrCreateProjectDefinition: UseAsyncRunner<ProjectDefinition, [ProjectDefinitionCreateRequest] | []>
  createTransientProjectDefinition: UseAsyncRunner<ProjectDefinition, [ProjectDefinitionCreateRequest] | []>
  createProjectDefinition: UseAsyncRunner<ProjectDefinition, [ProjectDefinitionCreateRequest] | []>
  updateProjectDefinition: UseAsyncRunner<ProjectDefinition, [ProjectDefinitionUpdateRequest] | []>
  deleteProjectDefinition: UseAsyncRunner<ProjectDefinition>
  duplicateProjectDefinition: UseAsyncRunner<ProjectDefinition, [DuplicateProjectDefinitionRequest]>
}

export interface UseProjectDefinitionOptions {
  resource: ProjectDefinitionResource
  projectId: Ref<string>
}

export function useProjectDefinition (options: UseProjectDefinitionOptions): UseProjectDefinition {
  const resource = options.resource
  const projectId = options.projectId

  const projectDefinitionOptional = ref<ProjectDefinition>()
  const projectDefinition = refDefault(projectDefinitionOptional, {
    universe: 'individual-housing',
    scopes: []
  })

  const readProjectDefinition = useAsyncRunner(async () => await resource.readProjectDefinition(projectId.value), projectDefinitionOptional)

  const readOrCreateProjectDefinition = useAsyncRunner(async (arg?: ProjectDefinitionCreateRequest) => {
    const data: ProjectDefinitionCreateRequest = arg ?? {
      ...projectDefinition.value
    }

    return await resource.readOrCreateProjectDefinition(projectId.value, data)
  }, projectDefinitionOptional)

  const createProjectDefinition = useAsyncRunner(async (request?: ProjectDefinitionCreateRequest) => {
    return await resource.createProjectDefinition(projectId.value, request)
  }, projectDefinitionOptional)

  const createTransientProjectDefinition = useAsyncRunner(async (request?: ProjectDefinitionCreateRequest) => {
    return await resource.createTransientProjectDefinition(request)
  }, projectDefinitionOptional)

  const updateProjectDefinition = useAsyncRunner(async (arg?: ProjectDefinitionUpdateRequest) => {
    const data: ProjectDefinitionUpdateRequest = arg ?? {
      ...projectDefinition.value
    }

    return await resource.updateProjectDefinition(projectId.value, data)
  }, projectDefinitionOptional)

  const deleteProjectDefinition = useAsyncRunner(async () => await resource.deleteProjectDefinition(projectId.value), projectDefinitionOptional)

  const duplicateProjectDefinition = useAsyncRunner(async (arg: DuplicateProjectDefinitionRequest) => await resource.duplicateProjectDefinition(projectId.value, arg), projectDefinitionOptional)

  return {
    projectDefinitionOptional,
    projectDefinition,
    projectId,
    readProjectDefinition,
    readOrCreateProjectDefinition,
    createTransientProjectDefinition,
    createProjectDefinition,
    updateProjectDefinition,
    deleteProjectDefinition,
    duplicateProjectDefinition
  }
}
