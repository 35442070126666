import { useAuth } from '../use/auth'
import { useAxios } from '../use/axios'
import config from '../config'

const { axiosOptions } = useAuth()

export const axiosApi = useAxios(
  axiosOptions({
    config: config.api,
    tenantHeader: true
  })
)
