<template>
  <v-sheet class="layout-vertical-page d-flex flex-column" :color="color" v-bind="$attrs" :style="style">
    <div v-if="$slots.header" class="flex-grow-0">
      <slot name="header"></slot>
    </div>
    <div class="flex-grow-1">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="flex-grow-0">
      <slot name="footer"></slot>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import vuetify from '../../plugins/vuetify'

export default defineComponent({
  name: 'LayoutVerticalPage',
  props: {
    color: {
      type: String as PropType<string>,
      default: 'transparent'
    }
  },
  setup: () => {
    const style = computed(() => {
      return {
        'min-height': `calc(100vh - ${vuetify.framework.application.top}px)`
      }
    })

    return { style }
  }
})
</script>

<style lang="scss">
</style>
