import type {
  DuplicateProjectDefinitionRequest,
  ProjectDefinition,
  ProjectDefinitionCreateRequest,
  ProjectDefinitionUpdateRequest
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'
import type { AxiosError, AxiosResponse } from 'axios'

export class ProjectDefinitionResource {
  public static injectable = injectable(ProjectDefinitionResource)

  async createProjectDefinition (projectId: string, data?: ProjectDefinitionCreateRequest): Promise<ProjectDefinition> {
    const response = await axiosApi.post(`/project/${projectId}/definition`, data)
    return response.data as ProjectDefinition
  }

  async createTransientProjectDefinition (data?: ProjectDefinitionCreateRequest): Promise<ProjectDefinition> {
    const response = await axiosApi.post('/project/transient-definition', data)
    return response.data as ProjectDefinition
  }

  async readProjectDefinition (projectId: string): Promise<ProjectDefinition> {
    const response = await axiosApi.get(`/project/${projectId}/definition`)
    return response.data as ProjectDefinition
  }

  async readOrCreateProjectDefinition (projectId: string, data: ProjectDefinitionCreateRequest): Promise<ProjectDefinition> {
    let response: AxiosResponse
    try {
      response = await axiosApi.get(`/project/${projectId}/definition`)
    } catch (e: unknown) {
      const axiosError = e as AxiosError
      if (!axiosError.isAxiosError) {
        throw e
      }

      if (axiosError.response?.status === 404) {
        response = await axiosApi.post(`/project/${projectId}/definition`, data)
      } else {
        throw e
      }
    }
    return response.data as ProjectDefinition
  }

  async updateProjectDefinition (projectId: string, data: ProjectDefinitionUpdateRequest): Promise<ProjectDefinition> {
    const response = await axiosApi.put(`/project/${projectId}/definition`, data)
    return response.data as ProjectDefinition
  }

  async deleteProjectDefinition (projectId: string): Promise<ProjectDefinition> {
    const response = await axiosApi.delete(`/project/${projectId}/definition`)
    return response.data as ProjectDefinition
  }

  async duplicateProjectDefinition (projectId: string, data: DuplicateProjectDefinitionRequest): Promise<ProjectDefinition> {
    const response = await axiosApi.post(`/project/${projectId}/definition/duplicate`, data)
    return response.data as ProjectDefinition
  }
}
