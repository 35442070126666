import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { refDefault } from '@vueuse/core'
import type { UniverseScopes } from 'app-model.carbon-saver'
import type { UniverseResource } from '../resources/universe'

export interface UseUniverseScopes {
  universeScopesOptional: Ref<UniverseScopes | undefined>
  universeScopes: Ref<UniverseScopes>
  universeId: Ref<string | undefined>

  readUniverseScopes: UseAsyncRunner<UniverseScopes>
}

export interface UseUniverseScopesOptions {
  resource: UniverseResource
  universeId: Ref<string | undefined>
}

export function useUniverseScopes (options: UseUniverseScopesOptions): UseUniverseScopes {
  const resource = options.resource
  const universeId = options.universeId

  const universeScopesOptional = ref<UniverseScopes>()
  const universeScopes = refDefault(universeScopesOptional, {
    categories: [],
    scopes: [],
    scopeLinks: []
  })

  const readUniverseScopes = useAsyncRunner(async () => universeId.value !== undefined ? await resource.readUniverseScopes(universeId.value) : universeScopes.value, universeScopesOptional)

  return {
    universeScopesOptional,
    universeScopes,
    universeId,
    readUniverseScopes
  }
}
