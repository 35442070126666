import Vue from 'vue'
import { useKeycloak, vueKeycloak } from '@baloise/vue-keycloak'
import type { KeycloakInitOptions } from 'keycloak-js'
import config from '../config'

export function initVueKeycloak () {
  const initOptions: KeycloakInitOptions = {
    checkLoginIframe: true,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    redirectUri: window.location.origin + window.location.pathname + window.location.search
  }

  Vue.use(vueKeycloak, {
    initOptions,
    config: config.keycloak
  })

  const kc = useKeycloak().keycloak

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  kc.onTokenExpired = async () => {
    await kc.updateToken(5)
  }
}
