import { injectable, injectRequired } from '../../../di'
import { UniverseResource } from '../resources/universe'
import type { UseUniverseScopes } from '../use/universe-scopes'
import { useUniverseScopes } from '../use/universe-scopes'

export class UniverseScopesState {
  public static injectable = injectable(UniverseScopesState)
  public readonly use: UseUniverseScopes

  constructor () {
    this.use = useUniverseScopes({
      resource: injectRequired(UniverseResource),
      universeId: injectRequired('ref<universeId>')
    })
  }
}
