import type { OrganisationMember } from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class OrganisationResource {
  public static injectable = injectable(OrganisationResource)

  async listOrganisationMembers (slug: string): Promise<OrganisationMember[]> {
    const response = await axiosApi.get(`organisation/${slug}/member`)
    return response.data as OrganisationMember[]
  }
}
