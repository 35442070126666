import type { UseAuth } from './auth'
import { useAuth } from './auth'
import type { Ref } from 'vue'
import { computed, unref } from 'vue'
import type { Project, ProjectSubjectPermissions } from 'app-model.carbon-saver'
import { ProjectSubjectType } from 'app-model.carbon-saver'

export interface UseCasl {
  project: UseCaslForSubject<ProjectSubjectPermissions>

  forProject (project?: Ref<Project | undefined>): UseCaslForSubject<ProjectSubjectPermissions>

  options: Required<UseCaslOptions>
}

export interface UseCaslOptions {
  useAuth?: UseAuth
}

export interface UseCaslForSubject<T extends string> {
  can (permission: T): Ref<boolean | undefined>

  cannot (permission: T): Ref<boolean | undefined>
}

export function useCasl (options?: UseCaslOptions): UseCasl {
  const effectiveOptions: Required<UseCaslOptions> = {
    useAuth: options?.useAuth ?? useAuth()
  }

  const forProject = (project?: Ref<Project | undefined>): UseCaslForSubject<ProjectSubjectPermissions> => {
    const { ability } = useAuth()

    const subjectType = computed(() => {
      const projectValue = unref(project)
      if (projectValue === undefined) return 'ProjectSubject'
      return new ProjectSubjectType({
        authorId: projectValue.createdBy?.id,
        shares: projectValue.shares?.map(s => ({ userId: s.user.id, role: s.role })) ?? [],
        organisationId: projectValue.organisationId
      })
    })

    const can = (permission: ProjectSubjectPermissions) => computed(() => {
      if (!ability.value) return
      return ability.value.can(permission, subjectType.value)
    })

    const cannot = (permission: ProjectSubjectPermissions) => computed(() => {
      if (!ability.value) return
      return ability.value.cannot(permission, subjectType.value)
    })

    return { can, cannot }
  }

  const project = (): UseCaslForSubject<ProjectSubjectPermissions> => {
    const { ability } = useAuth()

    const can = (permission: ProjectSubjectPermissions) => computed(() => {
      if (!ability.value) return
      return ability.value.can(permission, 'ProjectSubject')
    })

    const cannot = (permission: ProjectSubjectPermissions) => computed(() => {
      if (!ability.value) return
      return ability.value.cannot(permission, 'ProjectSubject')
    })

    return { can, cannot }
  }

  return {
    project: project(),
    forProject,
    options: effectiveOptions
  }
}
