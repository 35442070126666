import type { InjectionKey } from 'vue'
import { getCurrentInstance, inject as doInject, provide as doProvide } from 'vue'

// Use a WeakMap as fallback because provided entries are not available in components, only in children.
const fallbackMap = new WeakMap<Vue, Map<InjectionKey<unknown> | string, unknown>>()

export interface InjectableDecl<T> {
  symbol: InjectionKey<T> | string
  provide: () => void
}

export interface Injectable<T> {
  injectable: InjectableDecl<T>
}

export function toInjectionKey<T> (key: InjectionKey<T> | string | Injectable<T>): InjectionKey<T> | string {
  let injectionKey: InjectionKey<T> | string
  if ((typeof key === 'function' || typeof key === 'object') && ((key as Injectable<T>).injectable?.symbol) !== undefined) {
    injectionKey = ((key as Injectable<T>).injectable.symbol)
  } else {
    injectionKey = key as InjectionKey<T> | string
  }
  return injectionKey
}

export function inject<T> (key: InjectionKey<T> | string | Injectable<T>): T | undefined {
  const effectiveKey = toInjectionKey(key)
  let value: T | undefined = doInject(effectiveKey)
  if (value === undefined) {
    const instance = getCurrentInstance()
    if (instance !== null) {
      value = fallbackMap.get(instance.proxy)?.get(effectiveKey) as T | undefined
    }
  }
  return value
}

export function injectRequired<T> (key: InjectionKey<T> | string | Injectable<T>): T {
  const value = inject(key)
  if (value == null) {
    const effectiveKey = toInjectionKey(key)
    throw new Error(`injection key ${effectiveKey.toString()} can't be provided`)
  }
  return value
}

export function provide<T> (key: InjectionKey<T> | string, value: unknown) {
  const instance = getCurrentInstance()

  if (instance !== null) {
    let providedMap = fallbackMap.get(instance.proxy)
    if (providedMap === undefined) {
      providedMap = new Map()
      fallbackMap.set(instance.proxy, providedMap)
    }
    providedMap.set(key, value)
  }

  doProvide(key, value)
}

export function injectable<T> (Class: new() => T): InjectableDecl<T> {
  const symbol = Class.name

  return {
    symbol,
    provide: () => {
      provide(symbol, new Class())
    }
  }
}
