<template>
  <atom-chip v-if="inputCompletionLabel" v-bind="$attrs" v-on="$listeners" :color="inputCompletionColor"
             class="atom-project-input-completion-chip">
    {{ inputCompletionLabel }}
  </atom-chip>
</template>

<style scoped lang="scss">
.atom-project-badge {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent, toRef } from 'vue'
import type { Project } from 'app-model.carbon-saver'
import AtomChip from '../../../../components/atoms/AtomChip.vue'
import { useProjectDisplay } from '../../use/project-display'

export default defineComponent({
  name: 'AtomProjectInputCompletionChip',
  components: { AtomChip },
  props: {
    value: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  setup: (props) => {
    const valueData = toRef(props, 'value')

    const { inputCompletionLabel, inputCompletionColor } = useProjectDisplay({ project: valueData })

    return {
      inputCompletionLabel,
      inputCompletionColor
    }
  }
})
</script>
