import type { ProjectBundle } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectBundleResource } from '../resources/project-bundle'

export interface UseProjectBundle {
  projectBundle: Ref<ProjectBundle | undefined>
  projectId: Ref<string>
  readProjectBundle: UseAsyncRunner<ProjectBundle>
}

export interface UseProjectBundleOptions {
  resource: ProjectBundleResource
  projectId: Ref<string>
  projectBundle?: Ref<ProjectBundle | undefined>
}

export function useProjectBundle (options: UseProjectBundleOptions): UseProjectBundle {
  const resource = options.resource
  const projectId = options.projectId
  const projectBundle = options.projectBundle ?? ref<ProjectBundle | undefined>()

  const readProjectBundle = useAsyncRunner(async () => {
    return await resource.readProjectBundle(projectId.value)
  }, projectBundle, { hasPermissionSpec: { resource: 'project-bundle', scopes: 'read' } })

  return {
    projectBundle,
    readProjectBundle,
    projectId
  }
}
