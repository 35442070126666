import type { Project, ProjectBundle } from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class ProjectBundleResource {
  public static injectable = injectable(ProjectBundleResource)

  async readProjectBundle (id: string): Promise<ProjectBundle> {
    const response = await axiosApi.get(`/project-bundle/${id}`)
    return response.data as ProjectBundle
  }

  async createProjectBundle (projectBundle: ProjectBundle): Promise<Project> {
    const response = await axiosApi.post('/project-bundle', projectBundle)
    return response.data as Project
  }
}
