import { tl, tl_ } from '../../../plugins/i18next'
import type { ProjectShareRole } from 'app-model.carbon-saver'

export const availableShareRoles: ProjectShareRole[] = ['guest', 'contributor', 'administrator']

export function getShareRoleLabel (role: ProjectShareRole): string {
  return tl_(`common.share-role.${role}.label`)
}

export function getShareRoleDescription (role: ProjectShareRole): string {
  return tl_(`common.share-role.${role}.description`)
}

tl('common.share-role.administrator.label', 'Gérer')
tl('common.share-role.administrator.description', 'Voir, modifier, partager')
tl('common.share-role.contributor.label', 'Contribuer')
tl('common.share-role.contributor.description', 'Voir et modifier')
tl('common.share-role.guest.label', 'Voir')
tl('common.share-role.guest.description', 'Voir seulement')
