import { injectable, injectRequired } from '../../../di'
import type { UseProject } from '../use/project'
import { useProject } from '../use/project'
import { ProjectsState } from './projects'
import type { Project } from 'app-model.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { ProjectResource } from '../resources/project'

export class ProjectState {
  public static injectable = injectable(ProjectState)
  public readonly use: UseProject

  public projectScoreOpenedPanel: Ref<number | undefined> = ref(undefined)

  constructor () {
    const { projects } = injectRequired(ProjectsState).use
    const projectResource = injectRequired(ProjectResource)

    this.use = useProject({
      resource: projectResource,
      projects,
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { project?: Project }): this {
    if (data?.project !== undefined) {
      this.use.project.value = data.project
    }
    return this
  }
}
