import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectBundle } from 'app-model.carbon-saver'
import type { UseProjectBundle } from './project-bundle'
import FileSaver from 'file-saver'

export interface UseProjectBundleUx {
  downloadProjectBundleUx: UseAsyncRunner<ProjectBundle>
}

export interface UseProjectBundleUxOptions {
  useProjectBundle: UseProjectBundle
}

export function useProjectBundleUx (options: UseProjectBundleUxOptions): UseProjectBundleUx {
  const {
    useProjectBundle: { projectId, readProjectBundle }
  } = options

  const downloadProjectBundleUx = useAsyncRunner(async () => {
    const projectBundle = await readProjectBundle.run()
    const blob = new Blob([JSON.stringify(projectBundle, null, 2)], { type: 'application/json' })
    FileSaver.saveAs(blob, `export-project-${projectId.value}.json`)
    return projectBundle
  },
  undefined,
  {
    hasPermissionSpec: { resource: 'project-bundle', scopes: 'read' }
  })

  return {
    downloadProjectBundleUx
  }
}
