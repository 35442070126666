<template>
  <layout-vertical-page class="page-home">
    <v-container class="narrow pt-8 px-default" fluid>
      <v-row dense>
        <v-col class="text-right">
          {{ $tl('page-home.you-have-question', 'vous avez une question ?') }} <a class="font-weight-bold"
                                                                                  target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  :href="contactUsHref"> {{
            $tl('page-home.contact-us', 'nous contacter')
          }}</a>
        </v-col>
      </v-row>
      <v-row class="mt-0 align-end">
        <v-col class="flex-grow-1">
          <h2>
            {{ $tl('page-home.hello-user', 'bonjour') }}
            <template v-if="user"> {{ user.givenName }}</template>
          </h2>
          <span class="body-1">
            {{ $tl('page-home.welcome-to-user-space', 'bienvenue dans votre espace utilisateur Carbon Saver') }}
          </span>
        </v-col>
        <v-col class="flex-grow-0">
          <atom-button color="accent"
                       :async-runner="createTransientAndOpenProjectUx">
            <v-icon left>mdi-plus</v-icon>
            {{
              $tl('page-home.create-project', 'créer un projet')
            }}
          </atom-button>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <molecule-create-project-card tile dark></molecule-create-project-card>
        </v-col>
      </v-row>
      <v-row v-if="hasPermission('project')">
        <v-col>
          <organism-home-projects></organism-home-projects>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <atom-button color="accent"
                       :async-runner="createTransientAndOpenProjectUx">
            <v-icon left>mdi-plus</v-icon>
            {{
              $tl('page-home.create-project', 'créer un projet')
            }}
          </atom-button>
        </v-col>
      </v-row>
    </v-container>
    <template #footer>
      <organism-support-footer/>
    </template>
  </layout-vertical-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import OrganismSupportFooter from '../organisms/OrganismSupportFooter.vue'
import LayoutVerticalPage from '../layout/LayoutVerticalPage.vue'
import AtomButton from '../atoms/AtomButton.vue'
import OrganismHomeProjects from '../../modules/project/components/organisms/OrganismHomeProjects.vue'
import { useProject } from '../../modules/project/use/project'
import { useProjectOpenUx, useProjectUx } from '../../modules/project/use/project-ux'
import { useProjectDefinition } from '../../modules/project/use/project-definition'
import { ProjectsState } from '../../modules/project/states/projects'
import { injectRequired } from '../../di'
import { useAuth } from '../../use/auth'
import MoleculeCreateProjectCard from '../molecules/MoleculeCreateProjectCard.vue'
import { useI18n } from '../../use/i18n'
import { useProjectComponents } from '../../modules/project/use/project-components'
import { ProjectResource } from '../../modules/project/resources/project'
import { ProjectComponentResource } from '../../modules/project/resources/project-component'
import { ProjectDefinitionResource } from '../../modules/project/resources/project-definition'

export default defineComponent({
  name: 'PageHome',
  components: {
    MoleculeCreateProjectCard,
    OrganismHomeProjects,
    AtomButton,
    LayoutVerticalPage,
    OrganismSupportFooter
  },
  setup () {
    const drawer = ref<boolean>(false)
    const { user, hasPermission } = useAuth()
    const { currentLanguage } = useI18n()

    const contactUsHref = computed(() => {
      return `${currentLanguage.value.websiteUrl}/nous-contacter`
    })

    const projectsState = injectRequired(ProjectsState)
    const projectResource = injectRequired(ProjectResource)
    const projectDefinitionResource = injectRequired(ProjectDefinitionResource)
    const projectComponentResource = injectRequired(ProjectComponentResource)

    const useProjectInstance = useProject({
      resource: projectResource,
      projects: projectsState.use.projects,
      disabledProjects: projectsState.useDisabled.projects
    })

    const useProjectDefinitionInstance = useProjectDefinition({
      resource: projectDefinitionResource,
      projectId: useProjectInstance.projectId
    })

    const useProjectComponentsInstance = useProjectComponents({
      resource: projectComponentResource,
      projectId: useProjectInstance.projectId
    })

    const projectUx = useProjectUx({
      useProject: useProjectInstance
    })

    const projectOpenUx = useProjectOpenUx({
      useProject: useProjectInstance,
      useProjectDefinition: useProjectDefinitionInstance,
      useProjectComponents: useProjectComponentsInstance
    })

    return { drawer, user, contactUsHref, hasPermission, ...projectUx, ...projectOpenUx }
  }
})
</script>

<style lang="scss" scoped>
</style>
