import type { UseProject } from './project'
import { useRouter } from '../../../router'
import { tl } from '../../../plugins/i18next'
import type { UseProjectDefinition } from './project-definition'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Route } from 'vue-router'
import type { Project, ProjectShare } from 'app-model.carbon-saver'
import type { UseProjectComponents } from './project-components'
import type { ShareProjectFormData } from '../components/molecules/MoleculeShareProjectForm.vue'
import MoleculeShareProjectForm from '../components/molecules/MoleculeShareProjectForm.vue'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import { useCasl } from '../../../use/casl'
import { useSnackbar } from '../../../use/snackbar'

export interface UseProjectUx {
  deleteProjectUx: UseAsyncRunner<Project>
  disableProjectUx: UseAsyncRunner<Project>
  enableProjectUx: UseAsyncRunner<Project>

  batchDeleteProjectUx: UseAsyncRunner<Project[], [Project[]]>
  batchDisableProjectUx: UseAsyncRunner<Project[], [Project[]]>
  batchEnableProjectUx: UseAsyncRunner<Project[], [Project[]]>

  shareProjectUx: UseAsyncRunner<ProjectShare[]>
}

export interface UseProjectUxOptions {
  useProject: UseProject
}

export function useProjectUx (options: UseProjectUxOptions): UseProjectUx {
  const {
    useProject: { projectId, projectOptional, project, deleteProject, updateProjectDisabled, updateProjectShares }
  } = options

  const projectCasl = useCasl().forProject(projectOptional)

  const deleteProjectUx = useAsyncRunner(async () => { return await deleteProject.run() },
    undefined,
    {
      hasPermission: projectCasl.can('delete'),
      hasPermissionSpec: { resource: 'project', scopes: 'delete' },
      confirmDialog: {
        title: tl('project-ux.delete-confirmation-title', 'supprimer définitivement un projet'),
        message: tl('project-ux.delete-confirmation-message', 'souhaitez-vous bien supprimer définitivement ce projet ?'),
        yesNo: true
      }
    })

  const disableProjectUx = useAsyncRunner(async () => {
    return await updateProjectDisabled.run({ disabled: true })
  }, undefined, {
    hasPermission: projectCasl.can('delete'),
    hasPermissionSpec: { resource: 'project', scopes: 'disable' },
    confirmDialog: {
      title: tl('project-ux.disable-confirmation-title', 'supprimer un projet'),
      message: tl('project-ux.disable-confirmation-message', 'souhaitez-vous bien supprimer ce projet ?'),
      yesNo: true
    }
  })

  const enableProjectUx = useAsyncRunner(async () => {
    return await updateProjectDisabled.run({ disabled: false })
  }, undefined, {
    hasPermission: projectCasl.can('delete'),
    hasPermissionSpec: { resource: 'project', scopes: 'disable' }
  })

  const batchDeleteProjectUx = useAsyncRunner(async (projects: Project[]) => {
    const updatedProjects: Project[] = []
    for (const project of projects) {
      projectId.value = project.id
      projectOptional.value = project
      const deletedProject = await deleteProject.run()
      updatedProjects.push(deletedProject)
    }
    return updatedProjects
  }, undefined, {
    hasPermission: projectCasl.can('delete'),
    hasPermissionSpec: { resource: 'project', scopes: 'delete' },
    confirmDialog: {
      title: tl('project-ux.delete-confirmation-batch-title', 'supprimer définitivement ces projets'),
      message: tl('project-ux.delete-confirmation-batch-message', 'souhaitez-vous bien supprimer définitivement ces projet ?'),
      yesNo: true
    }
  })

  const batchDisableProjectUx = useAsyncRunner(async (projects: Project[]) => {
    const updatedProjects: Project[] = []
    for (const project of projects) {
      projectId.value = project.id
      projectOptional.value = project
      const updatedProject = await updateProjectDisabled.run({ disabled: true })
      updatedProjects.push(updatedProject)
    }
    return updatedProjects
  }, undefined, {
    hasPermission: projectCasl.can('delete'),
    hasPermissionSpec: { resource: 'project', scopes: 'disable' },
    confirmDialog: {
      title: tl('project-ux.disable-confirmation-batch-title', 'supprimer ces projets'),
      message: tl('project-ux.disable-confirmation-batch-message', 'souhaitez-vous bien supprimer ces projet ?'),
      yesNo: true
    }
  })

  const batchEnableProjectUx = useAsyncRunner(async (projects: Project[]) => {
    const updatedProjects: Project[] = []
    for (const project of projects) {
      projectId.value = project.id
      projectOptional.value = project
      const updatedProject = await updateProjectDisabled.run({ disabled: false })
      updatedProjects.push(updatedProject)
    }
    return updatedProjects
  }, undefined, {
    hasPermission: projectCasl.can('delete'),
    hasPermissionSpec: { resource: 'project', scopes: 'disable' }
  })

  const sharesFormData: Ref<ShareProjectFormData> = ref({
    shares: project.value.shares ? [...project.value.shares] : []
  })

  watch(computed(() => project.value.shares), (shares) => {
    sharesFormData.value.shares = shares ? [...shares] : []
  })

  const snackbar = useSnackbar()

  const shareProjectUx = useAsyncRunner(() => {
    const returnValue = updateProjectShares.run(sharesFormData.value.shares)
    if (sharesFormData.value.shares.length > 0) {
      snackbar.showSnackbar({
        title: tl('project-ux.project-shared-snackbar.title', 'Projet partagé'),
        message: tl('project-ux.project-shared-snackbar.message', 'Les options de partage ont bien été enregistrées.'),
        icon: 'mdi-share',
        color: 'cs-green-malachite',
        closeable: true
      })
    } else {
      snackbar.showSnackbar({
        title: tl('project-ux.project-empty-shares-snackbar.title', 'Projet non partagé'),
        message: tl('project-ux.project-empty-shares-snackbar.message', 'Les options de partage ont bien été désactivées.'),
        icon: 'mdi-share',
        color: 'cs-green-malachite',
        closeable: true
      })
    }
    return returnValue
  }, undefined, {
    before: () => {
      sharesFormData.value.shares = project.value.shares ? [...project.value.shares] : []
    },
    hasPermission: updateProjectShares.hasPermission,
    confirmDialog: {
      title: tl('project-ux.share-dialog-title', 'Partager le projet'),
      message: tl('project-ux.share-dialog-subtitle', 'Vous pouvez configurer les personnes ayant accès à ce projet ainsi que leurs niveaux d\'accès.'),
      contentComponent: MoleculeShareProjectForm,
      contentComponentProps: { value: sharesFormData, project, showOrganisationRoleChip: true }
    }
  })

  return {
    deleteProjectUx,
    disableProjectUx,
    enableProjectUx,
    batchDeleteProjectUx,
    batchDisableProjectUx,
    batchEnableProjectUx,
    shareProjectUx
  }
}

export interface UseProjectOpenUx {
  openProjectUx: UseAsyncRunner<Route>
  createTransientAndOpenProjectUx: UseAsyncRunner<Route>
  createAndOpenProjectUx: UseAsyncRunner<Route>
  duplicateAndOpenProjectUx: UseAsyncRunner<Route>
}

export interface UseProjectOpenUxOptions {
  useProject: UseProject
  useProjectDefinition: UseProjectDefinition
  useProjectComponents: UseProjectComponents
}

export function useProjectOpenUx (options: UseProjectOpenUxOptions): UseProjectOpenUx {
  const {
    useProject: { project, projectId, projectOptional, createProject, duplicateProject },
    useProjectDefinition: {
      readOrCreateProjectDefinition,
      createTransientProjectDefinition,
      duplicateProjectDefinition,
      projectDefinitionOptional
    },
    useProjectComponents: { listProjectComponents, projectComponentsOptional, duplicateProjectComponents }
  } = options

  const router = useRouter()

  const projectTypeCasl = useCasl().forProject()
  const projectCasl = useCasl().forProject(projectOptional)

  const openProjectUx = useAsyncRunner(async () => {
    await readOrCreateProjectDefinition.runOnce()
    await listProjectComponents.runOnce()

    return await router.push({
      name: 'project',
      params: {
        projectId: project.value.id,
        project: projectOptional.value as any,
        projectDefinition: projectDefinitionOptional.value as any,
        projectComponents: projectComponentsOptional.value as any
      }
    })
  }, undefined, {
    hasPermission: projectCasl.can('read'),
    hasPermissionSpec: { resource: 'project', scopes: 'read' }
  })

  const createAndOpenProjectUx = useAsyncRunner(async () => {
    const project = await createProject.run()
    projectId.value = project.id

    return await openProjectUx.run()
  }, undefined, {
    hasPermission: projectTypeCasl.can('create'),
    hasPermissionSpec: { resource: 'project', scopes: 'create' }
  })

  const createTransientAndOpenProjectUx = useAsyncRunner(async () => {
    const project: Project = {
      id: 'transient'
    }
    projectId.value = project.id
    projectOptional.value = project

    await createTransientProjectDefinition.runOnce()

    return await router.push({
      name: 'project',
      params: {
        projectId: project.id,
        project: projectOptional.value as any,
        projectDefinition: projectDefinitionOptional.value as any,
        projectComponents: projectComponentsOptional.value as any
      }
    })
  }, undefined, {
    hasPermission: projectTypeCasl.can('create'),
    hasPermissionSpec: { resource: 'project', scopes: 'create' }
  })

  const duplicateAndOpenProjectUx = useAsyncRunner(async () => {
    const duplicatedProject = await duplicateProject.run()
    await duplicateProjectDefinition.run({ targetProjectId: duplicatedProject.id })
    await duplicateProjectComponents.run({ targetProjectId: duplicatedProject.id })

    projectId.value = duplicatedProject.id
    return await openProjectUx.run()
  }, undefined, {
    hasPermission: projectCasl.can('read') && projectTypeCasl.can('create'),
    hasPermissionSpec: { resource: 'project', scopes: 'create' },
    confirmDialog: {
      title: tl('open-project-ux.duplicate-confirmation-title', 'dupliquer le projet'),
      subtitle: tl('open-project-ux.duplicate-confirmation-subtitle', 'souhaitez-vous bien dupliquer ce projet ?'),
      message: tl('open-project-ux.duplicate-confirmation-message', 'toutes les données du projet seront dupliquées dans un nouveau projet à l\'exception des données de partage. Vous pourrez ajouter des membres en cliquant sur <strong>partager</strong> dans le projet.'),
      messageHtml: true,
      confirmLabel: tl('open-project-ux.duplicate-confirm-label', 'oui, dupliquer'),
      cancelLabel: tl('open-project-ux.duplicate-cancel-label', 'non, annuler'),
      yesNo: true
    }
  })

  return {
    openProjectUx,
    createTransientAndOpenProjectUx,
    createAndOpenProjectUx,
    duplicateAndOpenProjectUx
  }
}
