import { injectable, injectRequired } from '../../../di'
import type { UseProjects } from '../use/projects'
import { useProjects } from '../use/projects'
import { ProjectResource } from '../resources/project'

export class ProjectsState {
  public static injectable = injectable(ProjectsState)
  public readonly use: UseProjects
  public readonly useDisabled: UseProjects

  constructor () {
    this.use = useProjects({
      resource: injectRequired(ProjectResource)
    })

    this.useDisabled = useProjects({
      resource: injectRequired(ProjectResource),
      disabled: true
    })
  }
}
