<template>
  <component :is="component"
             :key="value"
             small
             class="roles"
             v-on="$listeners"
             v-bind="$attrs"
             :disabled="disabled"
             :color="roleColor"
             :class="roleClass">
    {{ roleDisplayName }}
  </component>
</template>

<style scoped lang="scss">
</style>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'
import AtomChip from '../../../../components/atoms/AtomChip.vue'
import { getRoleDisplayName } from '../../use/organisation.js'

export default defineComponent({
  name: 'AtomOrganisationMemberRoleChip',
  components: { AtomChip },
  props: {
    value: {
      type: String,
      required: true
    },
    text: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { value, text } = toRefs(props)

    const roleColor = computed(() => {
      if (props.disabled) return undefined
      if (text.value) return undefined

      if (value.value === 'administrator') {
        return 'primary darken3'
      }

      return 'primary lighten5'
    })

    const roleClass = computed(() => {
      if (props.disabled) return undefined
      if (text.value) return undefined

      if (value.value === 'administrator') {
        return ''
      }

      return 'text--primary-darken3'
    })

    const component = computed(() => {
      return text.value ? 'span' : 'atom-chip'
    })

    const roleDisplayName = computed(() => {
      return getRoleDisplayName(value.value)
    })

    return {
      roleColor, roleClass, component, roleDisplayName
    }
  }
})
</script>
