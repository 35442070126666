import type { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import merge from 'lodash/merge'
import type { KeycloakConfig } from 'keycloak-js'
import { tenantResolver } from './tenant'

export interface GTMConfig {
  id?: string
}

export interface SentryConfig {
  enabled?: boolean
  dsn?: string
  tracesSampleRate?: number
  environment?: string
  release?: string
  tracingOrigins?: string[]
}

export interface AppConfig {
  version: string
}

export interface TenantConfig {
  appSubdomainPrefix?: string
  headerName?: string
  tenantId?: string
}

export interface HotjarConfig {
  id: string
  isProduction?: boolean
  snippet?: number
}

export interface Config {
  api: AxiosRequestConfig
  app: AppConfig
  tenant: TenantConfig
  keycloak: KeycloakConfig & { disabled?: boolean, accountApi: AxiosRequestConfig }
  gtm: GTMConfig
  sentry: SentryConfig
  hotjar: HotjarConfig
}

function getStringOrDefault (envValue: string | boolean | undefined | null, defaultValue: string): string {
  if (envValue === null || envValue === undefined || envValue === false || envValue === true) {
    return defaultValue
  }
  return envValue
}

function getBooleanOrDefault (envValue: string | boolean | undefined | null, defaultValue: boolean): boolean {
  if (envValue === null || envValue === undefined || envValue === false) {
    return false
  }
  if (envValue === true) {
    return true
  }
  if (typeof envValue === 'string') {
    return envValue.toLowerCase() === 'true'
  }

  return defaultValue
}

let apiRequestHeaders: RawAxiosRequestHeaders = {}

if (import.meta.env.VITE_API_REQUEST_HEADERS_JSON != null &&
  typeof import.meta.env.VITE_API_REQUEST_HEADERS_JSON === 'string' &&
  import.meta.env.VITE_API_REQUEST_HEADERS_JSON !== '') {
  apiRequestHeaders = JSON.parse(import.meta.env.VITE_API_REQUEST_HEADERS_JSON)
}

const appSubdomainPrefix = typeof import.meta.env.VITE_TENANT_APP_SUBDOMAIN_PREFIX === 'string' ? import.meta.env.VITE_TENANT_APP_SUBDOMAIN_PREFIX : 'app-'

const defaultConfig: Config = {
  api: {
    baseURL: getStringOrDefault(import.meta.env.VITE_API_URL, '//api.carbon-saver.test'),
    headers: { ...apiRequestHeaders }
  },
  tenant: {
    appSubdomainPrefix,
    headerName: typeof import.meta.env.VITE_TENANT_HEADER_NAME === 'string' ? import.meta.env.VITE_TENANT_HEADER_NAME : 'X-CarbonSaver-Tenant',
    tenantId: tenantResolver(window.location.hostname, appSubdomainPrefix)
  },
  app: {
    version: getStringOrDefault(import.meta.env.VITE_APP_VERSION, 'v0.0.0-dev')
  },
  keycloak: {
    accountApi: {},
    disabled: getBooleanOrDefault(import.meta.env.VITE_KEYCLOAK_DISABLED, false),
    url: getStringOrDefault(import.meta.env.VITE_KEYCLOAK_URL, 'https://auth.carbon-saver.test'),
    clientId: getStringOrDefault(import.meta.env.VITE_KEYCLOAK_CLIENT_ID, 'carbon-saver-app'),
    realm: getStringOrDefault(import.meta.env.VITE_KEYCLOAK_REALM, 'carbon-saver')
  },
  gtm: {
    id: typeof import.meta.env.VITE_GTM_ID === 'string' ? import.meta.env.VITE_GTM_ID : undefined
  },
  sentry: {
    enabled: getBooleanOrDefault(import.meta.env.VITE_SENTRY_ENABLED, false),
    dsn: import.meta.env.VITE_SENTRY_DSN as string | undefined,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT as string | undefined,
    release: getStringOrDefault(import.meta.env.VITE_SENTRY_RELEASE, getStringOrDefault(import.meta.env.VITE_APP_VERSION, 'v0.0.0-dev')),
    tracesSampleRate: typeof import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE === 'string' ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) : undefined,
    tracingOrigins: typeof import.meta.env.VITE_SENTRY_TRACING_ORIGINS === 'string' ? import.meta.env.VITE_SENTRY_TRACING_ORIGINS.split(',') : ['app.carbon-saver.test']
  },
  hotjar: {
    id: import.meta.env.VITE_HOTJAR_ID,
    isProduction: getBooleanOrDefault(import.meta.env.VITE_HOTJAR_ENABLED, false)
  }
}

const config: Config = merge(defaultConfig, window.config)

if (config.tenant.tenantId != null) {
  config.keycloak.realm = config.tenant.tenantId
}

export default config
