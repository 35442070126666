import type { Ref } from 'vue'
import { ref } from 'vue'

export interface SnackbarButton {
  text: string
  action: () => any
}

export interface SnackbarOptions {
  message?: string | string[]
  error?: unknown
  title?: string
  icon?: string
  color?: string
  info?: string
  timeout?: number
  closeable?: boolean
  button?: SnackbarButton
  text?: boolean
  top?: boolean
  dark?: boolean
  light?: boolean
  rounded?: boolean | 'sm' | 'md' | 'lg' | 'xl' | 'pill' | 'circle'
  contentClass?: string | string[]
}

export interface UseSnackbar {
  visible: Ref<boolean>
  snackbarOptions: Ref<SnackbarOptions>
  showSnackbar: (options?: string | SnackbarOptions) => void
}

export const visible = ref(false)
export const snackbarOptions = ref<SnackbarOptions>({})

export function useSnackbar (): UseSnackbar {
  const showSnackbar: UseSnackbar['showSnackbar'] = (options) => {
    if (options === undefined) {
      snackbarOptions.value = {}
    } else if (typeof options === 'string') {
      snackbarOptions.value = { message: options }
    } else {
      snackbarOptions.value = options
    }

    visible.value = true
  }

  return {
    visible,
    snackbarOptions,
    showSnackbar
  }
}
