<template>
  <atom-user-exposed-badge class="atom-organisation-member-badge"
                           :value="value"
                           :small="small"
                           :disabled="value.disabled"
                           :show-email="showEmail"
                           :dark="dark" :no-fab="noFab">
    <template v-if="showOrganisationRolesChip && value.organisationMembership && value.organisationMembership.roles">
      <v-spacer></v-spacer>
      <div class="d-flex flex-column align-center">
        <atom-organisation-member-role-chip :key="role" v-for="role of value.organisationMembership.roles"
                                            :value="role" :disabled="value.disabled">
        </atom-organisation-member-role-chip>
      </div>
    </template>
    <template #after-subtitle>
      <div class="body-2 text-no-wrap"
           v-if="showOrganisationJoinedTimestamp && formattedOrganisationJoinedTimestamp !== undefined">
        {{ formattedOrganisationJoinedTimestamp }}
      </div>
      <div class="body-2 text-no-wrap"
           v-if="showOrganisationRoles && value.organisationMembership && value.organisationMembership.roles">
        <atom-organisation-member-role-chip :key="role" v-for="role of value.organisationMembership.roles" :value="role"
                                            text></atom-organisation-member-role-chip>
      </div>
    </template>
  </atom-user-exposed-badge>
</template>

<style scoped lang="scss">
.atom-organisation-member-badge {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, toRefs } from 'vue'
import type { OrganisationMember } from 'app-model.carbon-saver'
import AtomOrganisationMemberRoleChip from './AtomOrganisationMemberRoleChip.vue'
import { format } from 'date-fns'
import { tl } from '../../../../plugins/i18next'
import { useI18n } from '../../../../use/i18n'
import AtomUserExposedBadge from './AtomUserExposedBadge.vue'

export default defineComponent({
  name: 'AtomOrganisationMemberBadge',
  components: { AtomUserExposedBadge, AtomOrganisationMemberRoleChip },
  props: {
    value: {
      type: Object as PropType<OrganisationMember>,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    noFab: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: false
    },
    showOrganisationJoinedTimestamp: {
      type: Boolean,
      default: false
    },
    showOrganisationRoles: {
      type: Boolean,
      default: false
    },
    showOrganisationRolesChip: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { value } = toRefs(props)

    const { currentDateLocale } = useI18n()

    const email = computed(() => {
      if ((value.value as OrganisationMember).email) {
        return (value.value as OrganisationMember).email
      }
      return undefined
    })

    const formattedOrganisationJoinedTimestamp = computed<string | undefined>(() => {
      const joinedTimestamp = value.value.organisationMembership?.createdAt
      if (joinedTimestamp === undefined) return undefined

      const date = format(Date.parse(joinedTimestamp), 'P', { locale: currentDateLocale.value })

      return tl('atom-organisation-member-badge.joined-date', {
        defaultValue: 'à rejoins le {{date}}',
        date
      }).replaceAll('&#x2F;', '/')
    })

    return {
      formattedOrganisationJoinedTimestamp,
      email
    }
  }
})
</script>
