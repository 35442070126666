import type { Ref } from 'vue'
import { ref, watch } from 'vue'
import { CancelError } from '../errorHandler'
import type { ComponentPublicInstanceConstructor } from 'vue/types/v3-component-public-instance'

export interface ConfirmationDialogOptions {
  title?: string
  subtitle?: string
  cardComponent?: string | ComponentPublicInstanceConstructor
  cardComponentProps?: Record<string, unknown>
  message?: string
  messageHtml?: boolean
  messageComponent?: string | ComponentPublicInstanceConstructor
  messageComponentProps?: Record<string, unknown>
  contentComponent?: string | ComponentPublicInstanceConstructor
  contentComponentProps?: Record<string, unknown>
  actionsComponent?: string | ComponentPublicInstanceConstructor
  actionsComponentProps?: Record<string, unknown>
  yesNo?: boolean
  confirmLabel?: string
  cancelLabel?: string
  width?: number | string
}

export interface UseConfirmationDialog {
  visible: Ref<boolean>
  dialogOptions: Ref<ConfirmationDialogOptions>
  ok: () => void
  cancel: () => void
  confirmAction: <T>(action: () => Promise<T>, options?: string | ConfirmationDialogOptions) => Promise<T>
}

export const visible = ref(false)
let okValue = false

const ok = (): void => {
  okValue = true
  visible.value = false
}

const cancel = (): void => {
  okValue = false
  visible.value = false
}

export const dialogOptions = ref<ConfirmationDialogOptions>({})

export function useConfirmationDialog (): UseConfirmationDialog {
  const confirmAction: UseConfirmationDialog['confirmAction'] = async (action, options) => {
    if (options === undefined) {
      dialogOptions.value = {}
    } else if (typeof options === 'string') {
      dialogOptions.value = { message: options }
    } else {
      dialogOptions.value = options
    }

    visible.value = true

    return await new Promise((resolve, reject) => {
      const watcher = watch(visible, (newValue) => {
        if (!newValue) {
          if (okValue) {
            action().then(resolve).catch(reject)
          } else {
            reject(new CancelError())
          }
          watcher()
        }
      })
    })
  }

  return {
    visible,
    dialogOptions,
    ok,
    cancel,
    confirmAction
  }
}
