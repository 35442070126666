import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import i18next from 'i18next'
import { enUS, fr } from 'date-fns/locale'
import type { Locale } from 'date-fns'

export interface Language {
  code: string
  value: string
  label: string
  icon: string
  websiteUrl: string
}

const supportedLanguages: Language[] = [{
  code: 'fr',
  value: 'fr-FR',
  label: 'Français',
  icon: 'fi fi-fr',
  websiteUrl: 'https://www.carbon-saver.com'
}, {
  code: 'en',
  value: 'en-US',
  label: 'English (US)',
  icon: 'fi fi-us',
  websiteUrl: 'https://en.carbon-saver.com'
}]

const dateLocales: Record<string, Locale> = {
  'fr-FR': fr,
  'en-US': enUS
}

const defaultLanguage = supportedLanguages.find((language) => language.value.toLowerCase().startsWith(navigator.language)) ?? supportedLanguages[0]
const currentLanguageValueStorage = useLocalStorage<string>('i18n.currentLanguage', defaultLanguage.value, { listenToStorageChanges: false })
const currentLanguageValue = computed<string>({
  get: () => {
    return currentLanguageValueStorage.value
  },
  set: (value) => {
    currentLanguageValueStorage.value = value
  }
})
const currentLanguage = ref<Language>(defaultLanguage)
const currentDateLocale = ref<Locale>(dateLocales[defaultLanguage.value])
const userLanguage = ref<Language | null>(null)

const setUserLanguageFromCode = (locale: string | undefined): Language | undefined => {
  const found = supportedLanguages.find((s) => s.code === locale)
  userLanguage.value = found ?? null
  return found
}

watch(currentLanguage, (newValue) => {
  currentLanguageValue.value = newValue.value
})

watch(currentLanguageValue, (newValue) => {
  currentLanguage.value = supportedLanguages.find(l => l.value === newValue) ?? defaultLanguage
}, { immediate: true })

watch(currentLanguage, async (newValue) => {
  if (!supportedLanguages.map(s => s.value).includes(newValue.value)) {
    currentLanguage.value = defaultLanguage
  } else {
    await i18next.changeLanguage(newValue.value)
  }
  currentDateLocale.value = dateLocales[newValue.value]
})

watch(userLanguage, (newValue) => {
  const userLanguage = newValue ?? defaultLanguage
  currentLanguage.value = userLanguage
  currentLanguageValue.value = userLanguage.value
})

export interface UseI18n {
  supportedLanguages: Language[]
  defaultLanguage: Language
  currentLanguage: Ref<Language>
  currentDateLocale: Ref<Locale>
  userLanguage: Ref<Language | null>
  setUserLanguageFromCode: (locale: string) => Language | undefined
}

export function useI18n (): UseI18n {
  return {
    supportedLanguages,
    defaultLanguage,
    currentLanguage,
    currentDateLocale,
    userLanguage,
    setUserLanguageFromCode
  }
}
