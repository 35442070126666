import type { KeycloakComposable } from '@baloise/vue-keycloak'
import { useKeycloak } from '@baloise/vue-keycloak'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import type { KeycloakTokenParsed } from 'keycloak-js'
import { useSessionStorage, watchOnce } from '@vueuse/core'
import type { AxiosError, AxiosResponse } from 'axios'
import axios from 'axios'
import { isArray } from 'lodash'
import type { NavigationGuardNext, Route } from 'vue-router'
import type { AxiosOptions } from './axios'
import { useAxios } from './axios'
import type { HasPermissionSpec, UseAuth, User, UserPermissions, UserPermissionScope } from './auth'
import { useI18n } from './i18n'
import { getLicenseType, showSubscribeDialog } from './license'
import type { RouteMeta } from '../router'
import { initVueKeycloak } from '../plugins/vue-keycloak'
import type { AppAbility, OrganisationMembership, UserRole } from 'app-model.carbon-saver'
import { CaslAbilityFactory } from 'app-model.carbon-saver'
import { KeycloakRolesProvider } from '@oidc-adapters/keycloak/src/roles.js'
import config from '../config'

export type RawPermissions = RawPermission[]

export interface RawPermission {
  rsid: string
  rsname: string
  scopes: UserPermissionScope[]
}

export interface ParsedToken extends KeycloakTokenParsed {
  'auth_time': number
  'jti': string
  'iss': string
  'aud': string
  'typ': string
  'azp': string
  'at_hash': string
  'acr': string
  'sid': string
  'email_verified': boolean
  'name': string
  'preferred_username': string
  'given_name': string
  'locale': string
  'family_name': string
  'email': string
  'licenses': string[]
  'subscriptions': string[]
}

export interface UserPermissionsData {
  [resource: string]: UserPermissionScope[]
}

export class KeycloakUserPermissions implements UserPermissions {
  constructor (
    public readonly data: Ref<UserPermissionsData | null> = ref(null),
    public readonly raw: Ref<RawPermissions | null> = ref(null)
  ) {
  }

  hasPermissionRef (resourceOrSpec: string | HasPermissionSpec, ...scopes: UserPermissionScope[]): Ref<boolean> {
    const hasPermission = ref(this.hasPermission(resourceOrSpec, ...scopes))

    watch(this.data, () => {
      hasPermission.value = this.hasPermission(resourceOrSpec, ...scopes)
    })

    return hasPermission
  }

  hasPermission (resourceOrSpec: string | HasPermissionSpec, ...scopes: UserPermissionScope[]): boolean {
    if (typeof resourceOrSpec === 'string') {
      return this.hasResourcePermission(resourceOrSpec, ...scopes)
    }

    const specs = isArray(resourceOrSpec) ? resourceOrSpec : [resourceOrSpec]

    for (const spec of specs) {
      const resources = isArray(spec.resource) ? spec.resource : [spec.resource]
      const scopes = isArray(spec.scopes) ? spec.scopes : [spec.scopes]

      for (const resource of resources) {
        if (!this.hasResourcePermission(resource, ...scopes)) {
          return false
        }
      }
    }

    return true
  }

  hasResourcePermission (resource: string, ...scopes: UserPermissionScope[]): boolean {
    const resourceScopes = this.data.value?.[resource]
    if (resourceScopes === undefined) return false
    if (scopes.length === 0) return true
    return resourceScopes.findIndex((v) => scopes.includes(v)) > -1
  }
}

function decodeToken (str: string): any {
  // This function is backported from keycloak JS Adapter.
  str = str.split('.')[1]

  str = str.replace(/-/g, '+')
  str = str.replace(/_/g, '/')
  switch (str.length % 4) {
    case 0:
      break
    case 2:
      str += '=='
      break
    case 3:
      str += '='
      break
    default:
      throw new Error('Invalid token')
  }

  str = decodeURIComponent(escape(atob(str)))

  return JSON.parse(str)
}

let singleton: UseAuth | undefined

function useAuthKeycloakFactory (): UseAuth {
  const caslAbilityFactory = new CaslAbilityFactory()

  initVueKeycloak()

  const useKeycloakInstance: KeycloakComposable = useKeycloak()
  const permissions = new KeycloakUserPermissions()
  const user = ref<User | null>(null)
  const meId = useSessionStorage<string>('user.me.id', '', { writeDefaults: false })
  const meOrganisationMemberships = useSessionStorage<OrganisationMembership[]>('organisation.me', [], { writeDefaults: false })

  const initOnce = async (): Promise<void> => {
    if (useKeycloakInstance.keycloak.authenticated === undefined) {
      await useKeycloakInstance.init()
    }
  }

  const navigationGuard = async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
    const handleAuth = async (): Promise<void> => {
      if (useKeycloakInstance.isPending.value) {
        return watchOnce(useKeycloakInstance.isPending, async () => {
          await handleAuth()
        })
      }

      if (!useKeycloakInstance.isAuthenticated.value) {
        next(false)
        // https://keycloak.discourse.group/t/refresh-token-request-returns-session-not-active/15723/3
        await useKeycloakInstance.keycloak.login({ scope: 'openid offline_access' })
        return
      }

      if (to.meta?.checkPermissions !== undefined && permissions.data.value === null) {
        return watchOnce(permissions.data, async () => {
          await handleAuth()
        })
      }

      const meta = to.meta as RouteMeta | undefined

      const permissionGuard = meta?.checkPermissions?.(permissions)
      if (permissionGuard === false) {
        if (from.name === null) {
          return next({ name: 'home' })
        } else {
          return next(false)
        }
      }

      const license = getLicenseType(user.value)
      const licenseGuard = meta?.checkLicense?.(license)
      if (licenseGuard === false) {
        try {
          await showSubscribeDialog()
          return
        } finally {
          if (from.name === null) {
            next({ name: 'home' })
          } else {
            next(false)
          }
        }
      }

      return next()
    }

    return await handleAuth()
  }

  const axiosOptions = (options: AxiosOptions): AxiosOptions => {
    return {
      token: useKeycloakInstance.token,
      ...options
    }
  }

  const updateUserFromIdToken = () => {
    const idTokenParsed: ParsedToken = useKeycloakInstance.keycloak.idTokenParsed as ParsedToken
    if (idTokenParsed != null) {
      const { setUserLanguageFromCode } = useI18n()
      const language = setUserLanguageFromCode(idTokenParsed.locale)

      user.value = {
        familyName: idTokenParsed.family_name,
        givenName: idTokenParsed.given_name,
        email: idTokenParsed.email,
        language: language?.value ?? '',
        licenses: idTokenParsed.licenses,
        subscriptions: idTokenParsed.subscriptions
      }
    } else {
      user.value = null
    }
  }

  const updateMe = async () => {
    const options = axiosOptions({ config: config.api, tenantHeader: true })
    if (options.token?.value && meId.value === '') {
      const axios = useAxios(options)
      const response = await axios.get('/user/me/id')
      const responseData = response.data as { id: string }
      meId.value = responseData.id
    }

    if (options.token?.value && meOrganisationMemberships.value.length === 0) {
      const axios = useAxios(options)
      const response = await axios.get('/organisation-membership/me')
      const responseData = response.data
      meOrganisationMemberships.value = responseData
    }
  }

  const logout = async (): Promise<void> => {
    meId.value = undefined
    meOrganisationMemberships.value = undefined
    await useKeycloakInstance.keycloak.logout({
      redirectUri: 'https://www.carbon-saver.com'
    })
  }

  const refresh = async (): Promise<void> => {
    if (useKeycloakInstance.isAuthenticated.value) {
      await refreshPermissions()
      await useKeycloak().keycloak.updateToken(Number.MAX_SAFE_INTEGER)
      updateUserFromIdToken()
    } else {
      permissions.raw.value = null
      permissions.data.value = null
    }
    await updateMe()
  }

  const refreshPermissions = async (): Promise<void> => {
    const tokenEndpoint: string = (useKeycloakInstance.keycloak as any).endpoints.token()

    const data = new URLSearchParams()
    data.append('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
    data.append('audience', 'carbon-saver-api')

    let response: AxiosResponse | undefined
    try {
      response = await axios.post(tokenEndpoint, data, {
        headers: {
          Authorization: `Bearer ${useKeycloakInstance.keycloak.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 403) {
        // When no permission is available, keycloak returns a 403.
      } else {
        throw e
      }
    }

    if (response !== undefined) {
      const rawPermissions: RawPermissions = decodeToken(response.data.access_token)?.authorization?.permissions
      const permissionsData: UserPermissionsData = {}
      for (const rawPermission of rawPermissions) {
        permissionsData[rawPermission.rsname] = rawPermission.scopes
      }

      permissions.data.value = permissionsData
      permissions.raw.value = rawPermissions
    } else {
      permissions.data.value = null
      permissions.raw.value = null
    }
  }

  watch(useKeycloakInstance.isAuthenticated, async () => {
    if (!useKeycloakInstance.isAuthenticated.value) {
      user.value = null
      meId.value = ''
      return
    }

    updateUserFromIdToken()
    await updateMe()
  }, { immediate: true })

  if (useKeycloakInstance.isPending.value) {
    watchOnce(useKeycloakInstance.isPending, () => {
      refresh().catch((e) => console.log(e))
    })
  } else if (useKeycloakInstance.keycloak !== null && useKeycloakInstance.keycloak !== undefined) {
    refresh().catch((e) => console.log(e))
  }

  const ability: Ref<AppAbility | null> = computed(() => {
    const idTokenParsed: ParsedToken = useKeycloakInstance.keycloak.idTokenParsed as ParsedToken
    if (idTokenParsed.sub === undefined) return null
    const rolesProvider = new KeycloakRolesProvider(idTokenParsed)
    return caslAbilityFactory.forUser({
      id: meId.value,
      roles: rolesProvider.getRoles() as UserRole[],
      organisations: meOrganisationMemberships.value
    })
  })

  return {
    initOnce,
    navigationGuard,
    axiosOptions,
    hasPermission: permissions.hasPermission.bind(permissions),
    hasPermissionRef: permissions.hasPermissionRef.bind(permissions),
    user,
    ability,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    refresh,
    logout
  }
}

export function useAuthKeycloak (): UseAuth {
  if (singleton === undefined) {
    singleton = useAuthKeycloakFactory()
  }
  return singleton
}
