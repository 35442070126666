/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ComponentOptions } from 'vue'
import Vue, { getCurrentInstance } from 'vue'
import type { NavigationGuard, RouteConfig, RouteRecord } from 'vue-router'
import VueRouter from 'vue-router'
import PageHome from './components/pages/PageHome.vue'
import type { UserPermissions } from './use/auth'
import { useAuth } from './use/auth'
import type { Position, Route } from 'vue-router/types/router'
import {
  buildRouterComponentId,
  buildRouterReport,
  buildRouterReset,
  buildRouterTags,
  buildRouterWorkflowStepQuery
} from './modules/project/use/project-workflow.router'
import type { ProjectWorkflowStepQuery } from './modules/project/use/project-workflow.query'
import type { LicenseType } from 'app-model.carbon-saver'
import { useDebug } from './use/debug'
import PageAdvancedSettings from './components/pages/PageAdvancedSettings.vue'

Vue.use(VueRouter)

export interface RouteMeta {
  public?: boolean
  checkPermissions?: (permissions: UserPermissions) => boolean
  checkLicense?: (license: LicenseType) => boolean
  drawer?: boolean
  account?: boolean
  scrollToTop?: boolean
  savePosition?: boolean
  backgroundDark?: boolean
  projectMode?: 'workflow' | 'report'
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: PageHome
  },
  {
    path: '/advanced-settings',
    name: 'advanced-settings',
    component: PageAdvancedSettings
  },
  {
    path: '/tenant/switch/:tenant',
    name: 'tenant-switch',
    component: async () => await import('./modules/tenant/components/pages/PageTenantSwitch.vue'),
    props: (route) => {
      return { model: route.params.tenant }
    },
    meta: {
      public: true
    } as RouteMeta
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    component: async () => await import('./modules/account/components/pages/PageUserSettings.vue')
  },
  {
    path: '/subscriptions/subscribe',
    name: 'subscribe',
    component: async () => await import('./modules/subscription/components/pages/PageSubscribe.vue'),
    meta: {
      drawer: false
    }
  },
  {
    path: '/subscriptions/subscribe-new',
    name: 'subscribe-new',
    component: async () => await import('./modules/subscription/components/pages/PageSubscribeNew.vue'),
    meta: {
      drawer: false
    }
  },
  {
    path: '/subscriptions/checkout-new-return',
    name: 'checkout-new-return',
    component: async () => await import('./modules/subscription/components/pages/PageCheckoutNewReturn.vue'),
    meta: {
      drawer: false
    }
  },
  {
    path: '/subscriptions/checkout-existing-return',
    name: 'checkout-existing-return',
    component: async () => await import('./modules/subscription/components/pages/PageCheckoutExistingReturn.vue'),
    meta: {
      drawer: false
    }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: async () => await import('./modules/subscription/components/pages/PageSubscriptions.vue')
  },
  {
    path: '/tenant/members',
    name: 'tenant-members',
    component: async () => await import('./modules/tenant/components/pages/PageTenantMembers.vue'),
    meta: {
      checkPermissions: permissions => permissions.hasPermission('tenant-member')
    } as RouteMeta
  },
  {
    path: '/tenant/configuration',
    name: 'tenant-configuration',
    component: async () => await import('./modules/tenant/components/pages/PageTenantConfiguration.vue'),
    meta: {
      checkPermissions: permissions => permissions.hasPermission('tenant-member', 'update')
    } as RouteMeta
  },
  {
    path: '/tenant/admin',
    name: 'tenant-admin',
    component: async () => await import('./modules/tenant-admin/components/pages/PageTenantAdmin.vue'),
    meta: {
      checkPermissions: permissions => permissions.hasPermission('tenant-admin')
    } as RouteMeta
  },
  {
    path: '/projects',
    name: 'projects',
    component: async () => await import('./modules/project/components/pages/PageProjects.vue'),
    meta: {
      checkLicense: license => license !== 'free',
      checkPermissions: permissions => permissions.hasPermission('project', 'read')
    } as RouteMeta
  },
  {
    path: '/project/:projectId/public-share',
    name: 'project-public-share',
    component: async () => await import('./modules/project/components/pages/PageProjectPublicShare.vue'),
    props: true,
    meta: {
      public: true,
      account: false,
      drawer: false
    } as RouteMeta
  },
  {
    path: '/project/:projectId',
    name: 'project',
    component: async () => await import('./modules/project/components/pages/PageProject.vue'),
    props: (route) => {
      return {
        ...route.params,
        dispatch: route.name === 'project'
      }
    },
    meta: {
      account: false,
      drawer: false
    } as RouteMeta,
    children: [
      {
        path: 'infos',
        name: 'project-infos',
        component: async () => await import('./modules/project/components/pages/PageProjectInfos.vue'),
        meta: {
          account: false,
          drawer: false
        } as RouteMeta
      },
      {
        path: 'define',
        name: 'project-define',
        component: async () => await import('./modules/project/components/pages/PageProjectDefine.vue'),
        meta: {
          account: false,
          drawer: false
        } as RouteMeta
      },
      {
        path: 'workflow',
        name: 'project-workflow',
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta,
        props: (route: Route) => {
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { tags, report, reset, componentId }
        }
      },
      {
        path: 'workflow/continue',
        name: 'project-workflow-continue',
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        props: (route) => {
          const stepQuery: ProjectWorkflowStepQuery = { inputRequired: true }
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId, pickFirst: true }
        },
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'workflow/start',
        name: 'project-workflow-start',
        props: (route) => {
          const stepQuery = buildRouterWorkflowStepQuery('start', route)
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'workflow/tags',
        name: 'project-workflow-tags',
        props: (route) => {
          const stepQuery = buildRouterWorkflowStepQuery('tags', route)
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'workflow/component/:componentId',
        name: 'project-workflow-component',
        props: (route) => {
          const stepQuery = buildRouterWorkflowStepQuery('component', route)
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'workflow/add-component/:componentModelId',
        name: 'project-workflow-add-component',
        props: (route) => {
          const stepQuery = buildRouterWorkflowStepQuery('add-component', route)
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'workflow/end',
        name: 'project-workflow-end',
        props: (route) => {
          const stepQuery = buildRouterWorkflowStepQuery('end', route)
          const tags = buildRouterTags(route)
          const report = buildRouterReport(route)
          const reset = buildRouterReset(route)
          const componentId = buildRouterComponentId(route)

          return { stepQuery, tags, report, reset, componentId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectWorkflow.vue'),
        meta: {
          account: false,
          drawer: false,
          scrollToTop: true,
          backgroundDark: true,
          projectMode: 'workflow'
        } as RouteMeta
      },
      {
        path: 'report',
        name: 'project-report',
        component: async () => await import('./modules/project/components/pages/PageProjectReport.vue'),
        meta: {
          account: false,
          drawer: false,
          savePosition: true,
          projectMode: 'report'
        } as RouteMeta,
        props: (route) => {
          const debug = !!route.query.debug

          const tags = buildRouterTags(route)
          const componentId = buildRouterComponentId(route)

          return { debug, tags, componentId }
        }
      },
      {
        path: 'report/advice-page/:pageId?',
        name: 'project-report-advice-page',
        props: (route) => {
          const pageId = route.params.pageId
          return { advicePageId: pageId }
        },
        component: async () => await import('./modules/project/components/pages/PageProjectReport.vue'),
        meta: {
          account: false,
          drawer: false,
          projectMode: 'report'
        } as RouteMeta
      }
    ]
  }
]

const savedPositionsByRouteRecordPath: Map<RouteRecord['path'], {
  route: Route,
  savedPosition: Position
}> = new Map()

export const router: VueRouter = new VueRouter({
  mode: 'history',
  scrollBehavior: (to: Route, from: Route, savedPosition: Position | void) => {
    if (to.meta !== undefined && (to.meta as RouteMeta).scrollToTop) {
      return { x: 0, y: 0 }
    }

    if (!savedPosition) {
      const matchedRoute = to.matched.at(-1)
      if (matchedRoute) {
        const saved = savedPositionsByRouteRecordPath.get(matchedRoute.path)
        if (saved) {
          if (saved.route.path === to.path) {
            savedPosition = saved.savedPosition
          } else {
            savedPositionsByRouteRecordPath.delete(matchedRoute.path)
          }
        }
      }
    }

    return savedPosition
  },
  routes
})

const { handleRouteDebug } = useDebug()

router.afterEach((to, from) => {
  if (from.meta?.savePosition) {
    const matchedRoute = from.matched.at(-1)
    if (matchedRoute) {
      const savedPosition = { x: window.scrollX, y: window.scrollY }
      savedPositionsByRouteRecordPath.set(matchedRoute.path, {
        route: from,
        savedPosition
      })
    }
  }
})

router.beforeEach(async (to, from, next) => {
  handleRouteDebug(to)

  if (to.meta?.public === true) {
    return next()
  }

  const { initOnce, navigationGuard } = useAuth()
  await initOnce()
  await navigationGuard(to, from, next)
})

export function useRouter (): VueRouter {
  return router
}

function onHook (name: keyof ComponentOptions<Vue>, callback: (...args: any) => void) {
  const vm = getCurrentInstance()
  const merge = Vue.config.optionMergeStrategies[name]
  if (vm && merge) {
    const prototype = Object.getPrototypeOf(vm.proxy.$options)
    prototype[name] = merge(vm.proxy.$options[name], callback)
  }
}

export function onBeforeRouteUpdate (callback: NavigationGuard) {
  return onHook('beforeRouteUpdate', callback)
}

export function onBeforeRouteLeave (callback: NavigationGuard) {
  return onHook('beforeRouteLeave', callback)
}

export function onBeforeRouteEnter (callback: NavigationGuard) {
  return onHook('beforeRouteEnter', callback)
}
