import { injectable, injectRequired } from '../../../di'
import type { ProjectBundle } from 'app-model.carbon-saver'
import type { UseProjectBundle } from '../use/project-bundle'
import { useProjectBundle } from '../use/project-bundle'
import { ProjectBundleResource } from '../resources/project-bundle'

export class ProjectBundleState {
  public static injectable = injectable(ProjectBundleState)
  public readonly use: UseProjectBundle

  constructor () {
    const projectBundleResource = injectRequired(ProjectBundleResource)

    this.use = useProjectBundle({
      resource: projectBundleResource,
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectBundle?: ProjectBundle }): this {
    if (data?.projectBundle !== undefined) {
      this.use.projectBundle.value = data.projectBundle
    }
    return this
  }
}
