import type { Component, ComponentModelExposed, ComponentProperty, ComponentPropertySymbol } from 'engine.carbon-saver'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import { refDefault } from '@vueuse/core'
import type { ProjectComponentResource } from '../resources/project-component'
import type { CreateProjectComponentRequestProperty, DuplicateProjectComponentsRequest } from 'app-model.carbon-saver'
import { tl } from '../../../plugins/i18next'
import AtomDeleteComponentConfirmMessage from '../components/atoms/AtomDeleteComponentConfirmMessage.vue'

export interface UseProjectComponents {
  projectComponentsOptional: Ref<Component[] | undefined>
  projectComponents: Ref<Component[]>
  listProjectComponents: UseAsyncRunner<Component[]>
  duplicateProjectComponents: UseAsyncRunner<Component[], [DuplicateProjectComponentsRequest]>

  writePropertyValue: UseAsyncRunner<void, [component: Component | string, property: ComponentProperty | string, value: string | number | boolean]>
  writePropertyValueSymbol: UseAsyncRunner<void, [component: Component | string, property: ComponentProperty | string, symbol: ComponentPropertySymbol]>
  deletePropertyValue: UseAsyncRunner<void, [component: Component | string, property: ComponentProperty | string]>

  createComponent: UseAsyncRunner<Component, [model: ComponentModelExposed | string, parentComponent?: Component | string, properties?: CreateProjectComponentRequestProperty[]]>
  deleteComponent: UseAsyncRunner<void, [component: Component | string]>

  writeComponentLabel: UseAsyncRunner<void, [component: Component | string, label: string | undefined]>
}

export interface UseProjectComponentsOptions {
  resource: ProjectComponentResource
  projectId: Ref<string>
}

export function useProjectComponents (options: UseProjectComponentsOptions): UseProjectComponents {
  const { projectId } = options

  const resource = options.resource
  const projectComponentsOptional = ref<Component[]>()
  const projectComponents = refDefault(projectComponentsOptional, [])

  const listProjectComponents = useAsyncRunner(async () => await resource.listProjectComponents(projectId.value), projectComponentsOptional)

  const duplicateProjectComponents = useAsyncRunner(async (data: DuplicateProjectComponentsRequest) => await resource.duplicateProjectComponents(projectId.value, data), projectComponentsOptional)

  const writePropertyValue = useAsyncRunner(async (component: Component | string, property: ComponentProperty | string, value: string | number | boolean) =>
    await resource.writePropertyValue(projectId.value, typeof component === 'string' ? component : component.id, typeof property === 'string' ? property : property.name, value)
  )

  const writePropertyValueSymbol = useAsyncRunner(async (component: Component | string, property: ComponentProperty | string, symbol: ComponentPropertySymbol) =>
    await resource.writePropertyValueSymbol(projectId.value, typeof component === 'string' ? component : component.id, typeof property === 'string' ? property : property.name, symbol)
  )

  const deletePropertyValue = useAsyncRunner(async (component: Component | string, property: ComponentProperty | string) =>
    await resource.deletePropertyValue(projectId.value, typeof component === 'string' ? component : component.id, typeof property === 'string' ? property : property.name)
  )

  const createComponent = useAsyncRunner(async (model: ComponentModelExposed | string, parentComponent?: Component | string, properties?: CreateProjectComponentRequestProperty[]) =>
    await resource.createProjectComponent(projectId.value, {
      modelId: typeof model === 'string' ? model : model.id,
      parentId: typeof parentComponent === 'string' ? parentComponent : parentComponent?.id,
      properties
    })
  )

  const deleteComponent = useAsyncRunner(
    async (component: Component | string) => await resource.deleteProjectComponent(projectId.value, typeof component === 'string' ? component : component.id),
    undefined,
    {
      confirmDialog: (component: Component | string) => {
        const title = tl('project-components.delete-confirmation-title', 'Supprimer ce composant')
        const message = tl('project-components.delete-confirmation-message', 'Souhaitez-vous bien supprimer définitivement ce composant ?')

        if (typeof component === 'string') {
          return {
            title,
            message,
            yesNo: true
          }
        } else {
          return {
            title,
            message,
            messageComponent: AtomDeleteComponentConfirmMessage,
            messageComponentProps: {
              component
            },
            yesNo: true
          }
        }
      }
    }
  )

  const writeComponentLabel = useAsyncRunner(async (component: Component | string, label: string | undefined) =>
    await resource.writeProjectComponentLabel(projectId.value, typeof component === 'string' ? component : component.id, label)
  )

  return {
    projectComponentsOptional,
    projectComponents,
    listProjectComponents,
    duplicateProjectComponents,
    writePropertyValue,
    writePropertyValueSymbol,
    deletePropertyValue,
    createComponent,
    deleteComponent,
    writeComponentLabel
  }
}
