import type { AxiosError } from 'axios'
import type { Vue } from 'vue/types/vue'
import type { SnackbarOptions } from './use/snackbar'
import { useSnackbar } from './use/snackbar'
import { tl, tl_ } from './plugins/i18next'
import { isArray } from 'lodash'
import { showSubscribeDialog } from './use/license.js'

export interface SupportedError {
  __handled__?: boolean
  __cancel__?: boolean
  __snackbar__?: SnackbarOptions
  message?: string
  display?: (snackbar: SnackbarOptions) => boolean
}

export interface ApiErrorData {
  statusCode: number
  error?: string
  message?: string | string[]
  i18n?: boolean
}

export class CancelError extends Error implements SupportedError {
  __cancel__ = true
}

function translate (str: string): string {
  return tl_(str, { ns: 'error-handler', ignoreMissing: true })
}

const { showSnackbar } = useSnackbar()

const errorHandler = function (error: unknown, vm?: Vue, info?: string): void {
  const err = error as SupportedError

  if ((error as Error).name === 'NavigationDuplicated') {
    // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
    (error as SupportedError).__handled__ = true
    return
  }

  if ((error as Error).name === 'Error' && (error as Error).message.startsWith('Navigation cancelled from')) {
    // https://stackoverflow.com/questions/65284853/vue-router-navigation-cancelled-from-to-password-with-a-new-navigation
    (error as SupportedError).__handled__ = true
    return
  }

  if (err.__handled__ !== true && err.__cancel__ !== true) {
    const snackbarOptions: SnackbarOptions = {
      title: tl('error-handler.default-title', 'une erreur inattendue s\'est produite'),
      icon: 'mdi-alert',
      message: err.message,
      color: 'error',
      text: true,
      closeable: true,
      ...err.__snackbar__
    }

    const maybeAxiosError: Partial<AxiosError> = err
    let silentError = false

    if (maybeAxiosError.isAxiosError === true && maybeAxiosError.response !== undefined) {
      const axiosError: AxiosError = err as AxiosError

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = axiosError.response!
      let apiErrorData: ApiErrorData | undefined
      const maybeApiErrorData = response.data as Partial<ApiErrorData> | undefined
      if (maybeApiErrorData?.message !== undefined && maybeApiErrorData?.statusCode !== undefined) {
        apiErrorData = response.data as ApiErrorData
      }

      if (response.status === 402) {
        showSubscribeDialog()
        return
      }

      if (response.statusText !== undefined) {
        snackbarOptions.title = response.statusText
      }

      if (apiErrorData?.i18n) {
        snackbarOptions.title = apiErrorData.error
        snackbarOptions.message = apiErrorData.message
      } else {
        if (response.status === 404) {
          snackbarOptions.title = tl('error-handler.not-found-title', 'ressource introuvable')
          snackbarOptions.message = tl('error-handler.not-found', 'la resource demandée n\'existe pas')
        } else if (response.status === 504 || response.status === 502) {
          snackbarOptions.title = tl('error-handler.unavailable-title', 'service indisponible')
          snackbarOptions.message = tl('error-handler.unavailable', 'le service n\'est pas disponible, veuillez réessayer ultérieurement')
        } else if (apiErrorData?.message !== undefined) {
          snackbarOptions.message = apiErrorData.message
        }

        if (response.status === 409) {
          snackbarOptions.title = tl('error-handler.conflict-title', 'cet objet existe déja')
        }

        if (response.status === 403) {
          snackbarOptions.title = tl('error-handler.access-denied-title', 'accès refusé')
        }

        if (response.status === 401) {
          snackbarOptions.title = tl('error-handler.unauthorized-title', 'accès non-autorisé')
          silentError = true // Workaround for obscures 401 errors
        }
      }

      if (snackbarOptions.title !== undefined) {
        snackbarOptions.title = translate(snackbarOptions.title)
      }

      if (snackbarOptions.message !== undefined) {
        if (isArray(snackbarOptions.message)) {
          snackbarOptions.message.map((m) => translate(m))
        } else {
          snackbarOptions.message = translate(snackbarOptions.message)
        }
      }
    }

    if (info !== undefined) {
      if (snackbarOptions.info !== undefined) {
        snackbarOptions.info += info
      } else {
        snackbarOptions.info = info
      }
    }

    err.__handled__ = true
    if (err?.display?.(snackbarOptions) === true) {
      // do nothing.
    } else {
      // TODO: showSnackbar is disabled as some errors are not fixed for now.
      if (!silentError) {
        showSnackbar(snackbarOptions)
      }
      console.error(err)
      if ((err as Partial<Error>).stack) {
        console.error((err as Error).stack)
      }
    }
  }
}

export default errorHandler

tl('error-handler.Changing enabled status of your own account is forbidden', 'Changer le status d\'activation de votre propre compte est interdit')
tl('error-handler.Changing roles of your own account is forbidden', 'Changer le rôle de votre propre compte est interdit')
tl('error-handler.User exists with same username', 'Il existe déjà un utilisateur avec cet e-mail')
tl('error-handler.User exists with same username or email', 'Il existe déjà un utilisateur avec cet e-mail')
