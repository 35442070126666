<template>
  <v-sheet class="organism-support-footer" color="white">
    <v-container fluid>
      <v-row class="no-gutters">
        <v-col cols="12" class="text-center pb-3">
          <v-btn text color="primary"
                 :href="batimpactExplanationHref"
                 rel="noopener noreferrer"
                 target="_blank">
            <v-icon left>mdi-beaker-outline</v-icon>
            {{
              $tl('organism-support-footer.batimpact-explanation-link', 'Qu\'est-ce que le Bat’Impact® ?')
            }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="no-gutters">
        <v-col cols="12" class="text-center pb-3">
          <span>
            {{
              $tl('organism-support-footer.supported-by', 'Soutenu par :')
            }}
          </span>
        </v-col>
        <v-col cols="12" class="text-center">
          <img class="mx-2" style="height: 100px" src="../../assets/logo-ministere.jpg"
               alt="Logo du Ministère de la transition écologique"/> <img class="mx-2" style="height: 100px"
                                                                          src="../../assets/Logo-GT-innovation.jpg"
                                                                          alt="Logo de Greetech innovation"/>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from '../../use/i18n'

export default defineComponent({
  name: 'OrganismSupportFooter',
  setup: () => {
    const { currentLanguage } = useI18n()

    const batimpactExplanationHref = computed(() => {
      return `${currentLanguage.value.websiteUrl}/batimpact-explanation`
    })

    return { batimpactExplanationHref }
  }
})
</script>

<style lang="scss"></style>
