import type { UseAsyncStateOptions, UseAsyncStateReturn } from '@vueuse/core'
import { useAsyncState } from '@vueuse/core'
import errorHandler from '../errorHandler'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { HasPermissionSpec } from './auth'
import { useAuth } from './auth'
import type { ConfirmationDialogOptions } from './confirmation-dialog'
import { useConfirmationDialog } from './confirmation-dialog'

export interface UseAsyncStateRunner<T, A extends any[] = []> extends Omit<UseAsyncStateReturn<T, A, boolean>, 'execute'> {
  run: (...args: A) => Promise<T>
  runOnce: (...args: A) => Promise<T>
}

export function useAsyncStateRunner<T, A extends any[] = []> (promise: Promise<T> | ((...args: A) => Promise<T>), initialState: T, options?: UseAsyncStateOptions<boolean>): UseAsyncStateRunner<T, A> {
  const asyncStateOptions: UseAsyncStateOptions<boolean> & { immediate: boolean } = {
    ...{ immediate: false, resetOnExecute: false },
    ...{ onError: errorHandler, ...options }
  }

  const asyncState = useAsyncState(promise as Promise<T> | ((...args: any[]) => Promise<T>), initialState, asyncStateOptions)

  const run = async (...args: A): Promise<T> => {
    const returnValue = await asyncState.execute(undefined, ...args)
    if (asyncState.error.value !== undefined) {
      throw asyncState.error.value
    }
    if (!asyncState.isReady.value) {
      throw new Error('AsyncRunner is not ready')
    }
    return returnValue as T
  }

  const runOnce = async (...args: A): Promise<T> => {
    const stateValue = asyncState.state.value

    if (!asyncState.isReady.value) {
      return await run(...args)
    }

    return stateValue as T
  }

  return { ...asyncState, run, runOnce }
}

export interface UseAsyncRunner<T, A extends any[] = []> extends Omit<UseAsyncStateReturn<T, A, boolean>, 'execute' | 'state'> {
  hasPermission: Ref<boolean>
  run: (...args: A) => Promise<T>
  runOnce: (...args: A) => Promise<T>
}

type AsyncRunnerConfirmDialogOptions = boolean | string | ConfirmationDialogOptions

type AsyncRunnerNoopOptions<T> = {
  switch: Ref<boolean>,
  value: T
}

export type HasPermission = Ref<boolean | undefined> | (() => Ref<boolean | undefined>)

export interface UseAsyncRunnerOptions<T, A extends any[] = []> extends UseAsyncStateOptions<boolean> {
  noop?: AsyncRunnerNoopOptions<T>,
  before?: () => Promise<void> | void,
  confirmDialog?: AsyncRunnerConfirmDialogOptions | ((...args: A) => AsyncRunnerConfirmDialogOptions)
  hasPermission?: HasPermission | HasPermission[]
  hasPermissionSpec?: HasPermissionSpec | HasPermissionSpec[]
}

export function useAsyncRunner<T, A extends any[] = []> (promise: Promise<T> | ((...args: A) => Promise<T>), returnValue?: Ref<T | undefined>, options?: UseAsyncRunnerOptions<T, A>): UseAsyncRunner<T, A> {
  const asyncStateOptions: UseAsyncStateOptions<boolean> & { immediate: boolean } = {
    ...{ immediate: false, resetOnExecute: false },
    ...{ onError: errorHandler, ...options }
  }

  const asyncState = useAsyncState(promise as Promise<T> | ((...args: any[]) => Promise<T>), undefined, asyncStateOptions)
  const effectiveReturnValue = returnValue !== undefined ? returnValue : ref<T | undefined>()

  const run = async (...args: A): Promise<T> => {
    if (options?.noop?.switch.value === true) {
      return options?.noop.value
    }
    let effectiveConfirmOptions: ConfirmationDialogOptions | undefined

    const resolvedConfirmOptions: AsyncRunnerConfirmDialogOptions | undefined = typeof options?.confirmDialog === 'function' ? options.confirmDialog(...args) : options?.confirmDialog
    if (resolvedConfirmOptions === true) {
      effectiveConfirmOptions = {}
    } else if (typeof resolvedConfirmOptions === 'object') {
      effectiveConfirmOptions = resolvedConfirmOptions
    }
    let returnValue: T | undefined
    await options?.before?.()
    if (effectiveConfirmOptions !== undefined) {
      const { confirmAction } = useConfirmationDialog()
      returnValue = await confirmAction(async () => await asyncState.execute(undefined, ...args), effectiveConfirmOptions)
    } else {
      returnValue = await asyncState.execute(undefined, ...args)
    }

    if (asyncState.error.value !== undefined) {
      throw asyncState.error.value
    }
    if (!asyncState.isReady.value) {
      throw new Error('AsyncRunner is not ready')
    }
    effectiveReturnValue.value = returnValue
    return returnValue as T
  }

  const runOnce = async (...args: A): Promise<T> => {
    if (!asyncState.isReady.value) {
      if (options?.noop?.switch.value === true) {
        return options?.noop.value
      }

      return await run(...args)
    }

    const returnValue = effectiveReturnValue.value
    return returnValue as T
  }

  /* eslint-disable vue/no-ref-as-operand */
  const permissions: Ref<boolean | undefined>[] = []

  if (options?.hasPermission !== undefined) {
    const permissionEntries = Array.isArray(options.hasPermission) ? options.hasPermission : [options.hasPermission]
    for (const permissionEntry of permissionEntries) {
      if (typeof permissionEntry === 'function') {
        permissions.push(permissionEntry())
      } else {
        permissions.push(permissionEntry)
      }
    }
  }

  if (options?.hasPermissionSpec !== undefined) {
    const permissionEntries = Array.isArray(options.hasPermissionSpec) ? options.hasPermissionSpec : [options.hasPermissionSpec]
    const { hasPermissionRef } = useAuth()
    for (const entry of permissionEntries) {
      permissions.push(hasPermissionRef(entry))
    }
  }

  /* eslint-enable vue/no-ref-as-operand */
  const resolvedHasPermission = computed(() => {
    for (const entry of permissions) {
      if (entry.value !== undefined && !entry.value) {
        return false
      }
    }

    return true
  })

  return { ...asyncState, hasPermission: resolvedHasPermission, run, runOnce }
}
