import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios, { AxiosHeaders } from 'axios'
import type { Ref } from 'vue'
import { watch } from 'vue'
import config from '../config'
import { tenantResolver } from '../tenant'
import type { Language } from './i18n'
import { useI18n } from './i18n'

export interface AxiosOptions {
  config?: AxiosRequestConfig
  token?: Ref<string>
  tenantHeader?: boolean
}

const addBearer = (request: AxiosRequestConfig, value: string): void => {
  if (request.headers == null) {
    request.headers = {}
  }

  const headerValue = `Bearer ${value}`
  if (request.headers instanceof AxiosHeaders) {
    request.headers.set('Authorization', headerValue)
  } else {
    request.headers.Authorization = headerValue
  }
}

const addTenantHeader = (request: AxiosRequestConfig, value: string): void => {
  if (config.tenant.headerName !== undefined) {
    if (request.headers == null) {
      request.headers = {}
    }

    if (request.headers instanceof AxiosHeaders) {
      request.headers.set(config.tenant.headerName, value)
    } else {
      request.headers[config.tenant.headerName] = value
    }
  }
}

const buildAcceptLanguageHeaderValue = (...additionalLanguages: string[]) => {
  let q = 1

  return [...additionalLanguages, ...navigator.languages]
    .slice(0, 10)
    .map(l => `${l};q=0.${10 - q++}`)
    .join(',')
}

const addAcceptLanguageHeader = (request: AxiosRequestConfig, value: Language): void => {
  if (config.tenant.headerName !== undefined) {
    if (request.headers == null) {
      request.headers = {}
    }

    const headerValue = buildAcceptLanguageHeaderValue(value.value, value.code)
    if (request.headers instanceof AxiosHeaders) {
      request.headers.set('Accept-Language', headerValue)
    } else {
      request.headers['Accept-Language'] = headerValue
    }
  }
}

const tenant = tenantResolver(window.location.hostname, config.tenant.appSubdomainPrefix)

const { userLanguage } = useI18n()

export function useAxios (options?: AxiosOptions): AxiosInstance {
  const instance = axios.create(options?.config)

  instance.interceptors.request.use(async config => {
    if (userLanguage.value) {
      addAcceptLanguageHeader(config, userLanguage.value)
    }
    return config
  })

  const bearer = options?.token
  if (bearer != null) {
    instance.interceptors.request.use(async config => {
      if (options?.tenantHeader === true && tenant !== undefined) {
        addTenantHeader(config, tenant)
      }
      if (bearer.value !== '') {
        addBearer(config, bearer.value)
        return config
      }
      return await new Promise((resolve) => {
        const stopWatch = watch(bearer, (bearerValue) => {
          if (bearerValue !== '') {
            stopWatch()
            addBearer(config, bearerValue)
            resolve(config)
          }
        })
      })
    })
  }

  instance.interceptors.response.use((response) => {
    if (response.status === 204) {
      response.data = null
    }
    return response
  })

  return instance
}
