import type { Location } from 'vue-router'
import { tl } from '../../../plugins/i18next'
import type { Route } from 'vue-router/types/router'
import type { Ref } from 'vue'
import { computed, ref, unref, watch } from 'vue'
import type { RouteMeta } from '../../../router'
import { router } from '../../../router'
import { buildRouterComponentId, buildRouterReport, buildRouterTags } from './project-workflow.router'
import { useProjectOpenUx, useProjectUx } from './project-ux'
import type { UseProject } from './project'
import { useCasl } from '../../../use/casl'
import { useProjectComponents } from './project-components'
import { useProjectDefinition } from './project-definition'
import type { ProjectComponentResource } from '../resources/project-component'
import type { ProjectDefinitionResource } from '../resources/project-definition'
import config from '../../../config'
import { useProjectBundleUx } from './project-bundle-ux'
import type { ProjectBundleResource } from '../resources/project-bundle'
import { useProjectBundle } from './project-bundle'

export type ProjectActionLocation = 'appbar' | 'drawer' | 'card' | 'card-main' | 'list' | 'list-main'

export interface ProjectAction {
  to?: Location | Ref<Location | undefined>
  label: string | Ref<string>
  location: ProjectActionLocation[]
  disabled?: boolean | Ref<boolean | undefined>
  hidden?: boolean | Ref<boolean | undefined>
  icon?: string | Ref<string | undefined>
  class?: string[] | Ref<string[] | undefined>
  iconAttrs?: Record<string, unknown> | Ref<Record<string, unknown> | undefined>
  itemAttrs?: Record<string, unknown> | Ref<Record<string, unknown> | undefined>
  perform?: () => Promise<unknown> | unknown
  available?: (route: Route) => boolean
  group?: string
}

export interface UseProjectActions {
  actions: Ref<ProjectAction[]>
  availableActions: Ref<ProjectAction[]>
  appbarActions: Ref<ProjectAction[]>
  cardActions: Ref<ProjectAction[]>
  cardMainActions: Ref<ProjectAction[]>
  listActions: Ref<ProjectAction[]>
  listMainActions: Ref<ProjectAction[]>
  drawerActions: Ref<ProjectAction[]>
}

export interface UseProjectActionsOptions {
  useProject: UseProject
  projectComponentResource: ProjectComponentResource,
  projectDefinitionResource: ProjectDefinitionResource,
  projectBundleResource: ProjectBundleResource,
}

export function useProjectActions (options: UseProjectActionsOptions): UseProjectActions {
  const asLocation = (route: Route): Location => ({
    name: route.name ?? undefined,
    path: route.path,
    params: route.params,
    query: route.query
  })

  const { project, projectOptional, projectId } = options.useProject
  const projectCasl = useCasl().forProject(projectOptional)

  const projectUx = useProjectUx({
    useProject: options.useProject
  })

  const projectComponents = useProjectComponents({
    resource: options.projectComponentResource,
    projectId
  })

  const projectDefinition = useProjectDefinition({
    resource: options.projectDefinitionResource,
    projectId
  })

  const projectBundle = useProjectBundle({
    resource: options.projectBundleResource,
    projectId
  })

  const projectOpenUx = useProjectOpenUx({
    useProject: options.useProject,
    useProjectComponents: projectComponents,
    useProjectDefinition: projectDefinition
  })

  const projectBundleUx = useProjectBundleUx({
    useProjectBundle: projectBundle
  })

  const lastWorkflowRoute = ref<Location>((router.currentRoute.meta as RouteMeta).projectMode === 'workflow' ? asLocation(router.currentRoute) : { name: 'project-workflow' })
  const lastReportRoute = ref<Location>((router.currentRoute.meta as RouteMeta).projectMode === 'report' ? asLocation(router.currentRoute) : { name: 'project-report' })

  const currentRoute = ref<Route>(router.currentRoute)

  watch(options.useProject.project, () => {
    lastWorkflowRoute.value = (router.currentRoute.meta as RouteMeta).projectMode === 'workflow' ? asLocation(router.currentRoute) : { name: 'project-workflow' }
    lastReportRoute.value = (router.currentRoute.meta as RouteMeta).projectMode === 'report' ? asLocation(router.currentRoute) : { name: 'project-report' }
  })

  router.afterEach((route) => {
    const report = buildRouterReport(route)
    const tags = buildRouterTags(route)
    const componentId = buildRouterComponentId(route)

    if ((router.currentRoute.meta as RouteMeta).projectMode === 'workflow' && report !== true && tags === undefined && componentId === undefined) {
      lastWorkflowRoute.value = asLocation(route)
    }
    if ((router.currentRoute.meta as RouteMeta).projectMode === 'report') {
      lastReportRoute.value = asLocation(route)
    }

    currentRoute.value = route
  })

  const actions = computed<ProjectAction[]>(() => {
    return [
      {
        label: tl('project-actions.open-project', 'Ouvrir'),
        hidden: computed(() => !projectOpenUx.openProjectUx.hasPermission.value),
        perform: () => projectOpenUx.openProjectUx.run(),
        location: ['card-main', 'list-main']
      },
      {
        label: tl('project-actions.configure-project', 'Configurer le projet'),
        icon: 'mdi-order-bool-ascending-variant',
        hidden: projectCasl.cannot('update'),
        to: { name: 'project-define' },
        location: ['appbar', 'drawer']
      },
      {
        label: tl('project-actions.project-infos', 'Infos projet'),
        icon: 'mdi-information-outline',
        hidden: projectCasl.cannot('read'),
        to: computed(() => ({ name: 'project-infos', params: { projectId: projectId.value } })),
        location: ['card', 'list', 'drawer']
      },
      {
        label: tl('project-actions.step-by-step-mode', 'Saisie pas à pas'),
        icon: '$vuetify.icons.stepByStepMode',
        to: lastWorkflowRoute.value,
        location: ['appbar', 'drawer'],
        hidden: projectCasl.cannot('update'),
        available: route => {
          return (route?.meta as RouteMeta).projectMode !== 'workflow'
        }
      },
      {
        label: tl('project-actions.report-mode', 'Rapport détaillé'),
        icon: '$vuetify.icons.entryMode',
        to: lastReportRoute.value,
        location: ['appbar', 'drawer'],
        hidden: projectCasl.cannot('read'),
        available: route => {
          return (route?.meta as RouteMeta).projectMode !== 'report'
        }
      },
      {
        label: tl('project-actions.share-project', 'Partager'),
        icon: 'mdi-share',
        hidden: computed(() => config.tenant.tenantId === undefined || !projectUx.shareProjectUx.hasPermission.value),
        perform: () => projectUx.shareProjectUx.run(),
        location: ['card', 'list', 'appbar', 'drawer']
      },
      {
        label: tl('project-actions.duplicate-project', 'Dupliquer'),
        icon: 'mdi-content-duplicate',
        hidden: computed(() => !projectOpenUx.duplicateAndOpenProjectUx.hasPermission.value),
        perform: () => projectOpenUx.duplicateAndOpenProjectUx.run(),
        location: ['card', 'list']
      },
      {
        label: tl('project-actions.export-project', 'Exporter'),
        icon: 'mdi-file-export-outline',
        hidden: computed(() => !projectBundleUx.downloadProjectBundleUx.hasPermission.value),
        perform: () => projectBundleUx.downloadProjectBundleUx.run(),
        location: ['card', 'list', 'drawer']
      },
      {
        label: tl('project-actions.history', 'Historique'),
        icon: 'mdi-history',
        disabled: true,
        location: ['drawer']
      },
      {
        label: tl('project-actions.restore', 'Restaurer'),
        icon: 'mdi-backup-restore',
        hidden: computed(() => !project.value.disabled || !projectUx.enableProjectUx.hasPermission.value),
        perform: () => projectUx.enableProjectUx.run(),
        location: ['card', 'list']
      },
      {
        label: tl('project-actions.delete', 'Supprimer'),
        icon: 'mdi-trash-can-outline',
        iconAttrs: computed(() => projectUx.disableProjectUx.hasPermission.value ? { color: 'error' } : {}),
        class: computed(() => projectUx.disableProjectUx.hasPermission.value ? ['error--text'] : []),
        hidden: computed(() => project.value.disabled || !projectUx.disableProjectUx.hasPermission.value),
        perform: () => projectUx.disableProjectUx.run(),
        location: ['card', 'list'],
        group: 'delete'
      },
      {
        label: tl('project-actions.delete-permanently', 'Supprimer définitivement'),
        icon: 'mdi-trash-can-outline',
        iconAttrs: computed(() => projectUx.deleteProjectUx.hasPermission.value ? { color: 'error' } : {}),
        class: computed(() => projectUx.deleteProjectUx.hasPermission.value ? ['error--text'] : []),
        hidden: computed(() => !project.value.disabled || !projectUx.deleteProjectUx.hasPermission.value),
        perform: () => projectUx.deleteProjectUx.run(),
        location: ['card', 'list'],
        group: 'delete'
      },
      {
        label: tl('project-actions.quit-project', 'Quitter le projet'),
        icon: 'mdi-exit-to-app',
        iconAttrs: { style: 'transform: rotate(180deg)' },
        to: { name: 'home' },
        location: ['drawer']
      }
    ]
  })

  const availableAppbarActions = computed(() => {
    return actions.value.filter(action => {
      if (action.available?.(currentRoute.value) === false) {
        return false
      }

      if (unref(action.hidden)) {
        return false
      }

      return unref(action.to)?.name !== currentRoute.value.name
    })
  })

  const availableActions = computed(() => {
    return actions.value.filter(action => {
      if (unref(action.hidden)) {
        return false
      }

      return true
    })
  })

  const listActions = computed(() => {
    return [...availableActions.value].filter((a) => a.location.includes('list'))
  })

  const listMainActions = computed(() => {
    return [...availableActions.value].filter((a) => a.location.includes('list-main'))
  })

  const cardActions = computed(() => {
    return [...availableActions.value].filter((a) => a.location.includes('card'))
  })

  const cardMainActions = computed(() => {
    return [...availableActions.value].filter((a) => a.location.includes('card-main'))
  })

  const appbarActions = computed(() => {
    return [...availableAppbarActions.value].filter((a) => a.location.includes('appbar'))
  })

  const drawerActions = computed(() => {
    return [...availableActions.value].filter((a) => a.location?.includes('drawer'))
  })

  return {
    actions,
    availableActions,
    appbarActions,
    listActions,
    listMainActions,
    cardActions,
    cardMainActions,
    drawerActions
  }
}
