import { injectable, injectRequired } from '../../../di'
import type { UseProjectAnswers } from '../use/project-answers'
import { useProjectAnswers } from '../use/project-answers'
import { ProjectAnswerResource } from '../resources/project-answer'
import type { ProjectAnswer } from 'app-model.carbon-saver'

export class ProjectAnswersState {
  public static injectable = injectable(ProjectAnswersState)
  public readonly use: UseProjectAnswers

  constructor () {
    this.use = useProjectAnswers({
      resource: injectRequired(ProjectAnswerResource),
      projectId: injectRequired('ref<projectId>')
    })
  }

  withData (data?: { projectAnswers?: ProjectAnswer[] }): this {
    if (data?.projectAnswers !== undefined) {
      this.use.projectAnswers.value = data.projectAnswers
    }
    return this
  }
}
