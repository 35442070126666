import Vue from 'vue'
import type { TOptions } from 'i18next'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'

import appFrFR from '../i18n/fr-FR/app.json'
import appEnUS from '../i18n/en-US/app.json'

import { useI18n } from '../use/i18n'

export interface I18nLabel {
  key: string
  options?: TOptions
}

Vue.use(VueI18Next)

const { supportedLanguages, currentLanguage } = useI18n()

i18next.init({
  lng: currentLanguage.value.value,
  defaultNS: 'app',
  supportedLngs: supportedLanguages.map(l => l.value),
  fallbackLng: ['fr-FR'],
  returnNull: false,
  returnEmptyString: false,
  compatibilityJSON: 'v3',
  simplifyPluralSuffix: true,
  /* eslint-disable quote-props */
  resources: {
    'fr-FR': {
      'app': appFrFR
    },
    'en-US': {
      'app': appEnUS
    }
  }
  /* eslint-enable quote-props */
}).catch((err) => {
  console.error(err)
})

const i18n = new VueI18Next(i18next)

export interface ApplyCaseOptions {
  html: boolean
  sentenceSeparatorChars: string[]
  blankChars: string[]
}

const defaultApplyCaseOptions: ApplyCaseOptions = {
  html: true,
  sentenceSeparatorChars: '.?!/'.split(''),
  blankChars: ' '.split('')
}

export function applyCase (input: string, options?: Partial<ApplyCaseOptions>): string {
  const effectiveOptions: ApplyCaseOptions = { ...options, ...defaultApplyCaseOptions }

  let inHtmlTag = false
  let output = ''
  let capitalizeNextChar = true
  const ignoreChars: string[] = effectiveOptions.blankChars

  let index = 0
  while (index < input.length) {
    let char = input[index]

    if (effectiveOptions.html && char === '>') {
      inHtmlTag = false
    } else if (effectiveOptions.html && char === '<') {
      inHtmlTag = true
    } else if (!inHtmlTag && effectiveOptions.sentenceSeparatorChars.includes(char)) {
      capitalizeNextChar = true
    } else if (!inHtmlTag && capitalizeNextChar && !ignoreChars.includes(char)) {
      char = char.toUpperCase()
      capitalizeNextChar = false
    }

    output += char

    index++
  }

  return output
}

export interface TOptionsExt extends TOptions {
  keepCase?: boolean
  dynamicContext?: string
  short?: boolean
  replacements?: Array<[TOptions, TOptions]>
  ignoreMissing?: boolean
}

// Custom $tl function available in templates to translate I18nLabel objects.
export function tl (i18nLabel: I18nLabel | string, options?: TOptionsExt | string): string {
  if (i18nLabel == null) return i18nLabel
  if (typeof i18nLabel === 'string') {
    i18nLabel = { key: i18nLabel }
  }

  if (typeof options === 'string') {
    options = { defaultValue: options }
  }

  let effectiveOptions: TOptionsExt = options == null ? {} : { ...options }

  if (i18nLabel.options != null) {
    effectiveOptions = effectiveOptions == null ? i18nLabel.options : { ...i18nLabel.options, ...effectiveOptions }
  }

  if (effectiveOptions.dynamicContext != null) {
    const dynamicContextKey = `${i18nLabel.key}|${effectiveOptions.dynamicContext}`
    const dynamicContextOut = i18next.t(dynamicContextKey, effectiveOptions)
    if (dynamicContextOut !== dynamicContextKey) {
      effectiveOptions.context = dynamicContextOut
    }
  }

  let out = i18next.t(i18nLabel.key, effectiveOptions)
  if (out === i18nLabel.key) {
    if (effectiveOptions.ignoreMissing === true) {
      return out
    }
    return `!${i18nLabel.key}!`
  }

  if (effectiveOptions?.short === true) {
    if (effectiveOptions?.replacements == null) {
      effectiveOptions.replacements = []
    }
    effectiveOptions.replacements.push([{ ...options, context: undefined }, { ...options, context: 'short' }])
  }

  if (effectiveOptions?.replacements != null) {
    for (const [searchOptions, replaceOptions] of effectiveOptions.replacements) {
      const searchString = i18next.t(i18nLabel.key, searchOptions)
      const replaceString = i18next.t(i18nLabel.key, replaceOptions)
      out = out.replace(searchString, replaceString)
    }
  }

  if (effectiveOptions.keepCase !== true) {
    out = applyCase(out)
  }

  return out
}

Vue.prototype.$tl = tl
Vue.prototype.$tl_ = tl

export const tl_ = tl

export default i18n
