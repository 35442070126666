import type {
  Component,
  ComponentPropertySymbol,
  EnhancedChoices,
  Report,
  TagExposed,
  Workflow
} from 'engine.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'
import type { CreateProjectComponentRequest, DuplicateProjectComponentsRequest } from 'app-model.carbon-saver'
import type { AxiosError, AxiosRequestConfig } from 'axios'

export interface ReadProjectWorkflowOptions {
  tags?: Required<Pick<TagExposed, 'key' | 'value'>>[],
  componentId?: string,
  report?: boolean,
  reset?: boolean,
  debug?: boolean
}

export interface ReadProjectReportOptions {
  tags?: Required<Pick<TagExposed, 'key' | 'value'>>[],
  componentId?: string,
  debug?: boolean
}

export class ProjectComponentResource {
  public static injectable = injectable(ProjectComponentResource)

  async listProjectComponents (projectId: string): Promise<Component[]> {
    const response = await axiosApi.get(`/project/${projectId}/component`)
    return response.data as Component[]
  }

  async readProjectWorkflow (projectId: string, options?: ReadProjectWorkflowOptions): Promise<Workflow> {
    const config: AxiosRequestConfig = { params: {} }
    if (options?.tags) {
      for (const tag of options.tags) {
        config.params[tag.key] = `${tag.value}`
      }
    }

    if (options?.componentId !== undefined) {
      config.params._componentId_ = options.componentId
    }

    if (options?.report === true) {
      config.params._report_ = 'true'
    }

    if (options?.debug !== undefined) {
      config.params._debug_ = options?.debug
    }

    const response = await axiosApi.get(`/project/${projectId}/component/workflow`, config)
    return response.data as Workflow
  }

  async readProjectMetadata (projectId: string): Promise<Workflow> {
    const response = await axiosApi.get(`/project/${projectId}/component/metadata`)
    return response.data as Workflow
  }

  async writePropertyValue (projectId: string, componentId: string, propertyName: string, value: string | number | boolean): Promise<void> {
    await axiosApi.put(`/project/${projectId}/component/${componentId}/property/${propertyName}`, { value })
  }

  async writePropertyValueSymbol (projectId: string, componentId: string, propertyName: string, symbol: ComponentPropertySymbol): Promise<void> {
    await axiosApi.put(`/project/${projectId}/component/${componentId}/property/${propertyName}`, { symbol })
  }

  async deletePropertyValue (projectId: string, componentId: string, propertyName: string): Promise<void> {
    await axiosApi.delete(`/project/${projectId}/component/${componentId}/property/${propertyName}`)
  }

  async createProjectComponent (projectId: string, data: CreateProjectComponentRequest): Promise<Component> {
    const response = await axiosApi.post(`/project/${projectId}/component`, data)
    return response.data as Component
  }

  async deleteProjectComponent (projectId: string, componentId: string): Promise<void> {
    await axiosApi.delete(`/project/${projectId}/component/${componentId}`)
  }

  async writeProjectComponentLabel (projectId: string, componentId: string, label: string | undefined): Promise<void> {
    await axiosApi.put(`/project/${projectId}/component/${componentId}/label`, label ? { label } : undefined)
  }

  async readPropertyEnhancedChoices (projectId: string, componentId: string, propertyName: string): Promise<EnhancedChoices | undefined> {
    try {
      const response = await axiosApi.get(`/project/${projectId}/component/${componentId}/property/${propertyName}/enhanced-choices`)
      return response.data as EnhancedChoices
    } catch (e) {
      if ((e as Partial<AxiosError>).isAxiosError) {
        const axiosError = e as AxiosError
        if (axiosError.response?.status === 404) {
          return
        }
      }
      throw e
    }
  }

  async duplicateProjectComponents (projectId: string, data: DuplicateProjectComponentsRequest): Promise<Component[]> {
    const response = await axiosApi.post(`/project/${projectId}/component/duplicate`, data)
    return response.data as Component[]
  }

  async readProjectReport (projectId: string, options?: ReadProjectReportOptions): Promise<Report> {
    const config: AxiosRequestConfig = { params: {} }
    if (options?.tags) {
      for (const tag of options.tags) {
        config.params[tag.key] = `${tag.value}`
      }
    }

    if (options?.componentId !== undefined) {
      config.params._componentId_ = options.componentId
    }

    if (options?.debug !== undefined) {
      config.params._debug_ = options?.debug
    }

    const response = await axiosApi.get(`/project/${projectId}/component/report`, config)
    return response.data as Report
  }
}
