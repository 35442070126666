<template>
  <v-menu class="molecule-project-actions-button" left>
    <template v-slot:activator="{ on, attrs }">
      <atom-button v-bind="{...$attrs, ...attrs}"
                   v-on="on"
                   :class="{'d-none': !hasActiveActions}"
                   :disabled="!hasActiveActions"
                   fab
                   small
                   icon-color="primary"
                   icon="mdi-dots-vertical">
      </atom-button>
    </template>
    <molecule-project-actions-list :right="right" :value="actions"></molecule-project-actions-list>
  </v-menu>
</template>

<style scoped lang="scss">
.molecule-project-actions-button {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, unref } from 'vue'
import MoleculeProjectActionsList from './MoleculeProjectActionsList.vue'
import type { ProjectAction } from '../../use/project-actions'
import AtomButton from '../../../../components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'MoleculeProjectActionsButton',
  components: { AtomButton, MoleculeProjectActionsList },
  props: {
    actions: {
      type: Array as PropType<ProjectAction[]>,
      required: true
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const hasActiveActions = computed(() => {
      return props.actions?.some(a => !unref(a.disabled))
    })

    return {
      hasActiveActions
    }
  }
})
</script>
