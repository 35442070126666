<template>
  <div class="atom-delete-component-confirm-message text-body-1">
    <div>
      {{
        $tl('project-components.delete-confirmation-message', 'Souhaitez-vous bien supprimer définitivement ce composant ?'),
      }}
    </div>
    <div class="text-center font-weight-bold pt-2 text-h6 black--text" v-if="componentLabel">
      {{ componentLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import type { Component } from 'engine.carbon-saver'
import { applyCase } from '../../../../plugins/i18next.js'

export default defineComponent({
  name: 'AtomDeleteComponentConfirmMessage',
  components: {},
  props: {
    component: {
      type: Object as PropType<Component>,
      required: true
    }
  },
  setup (props) {
    const componentLabel = computed(() => {
      if (props.component.label === undefined) return
      return applyCase(props.component.label)
    })

    return { componentLabel }
  }
})
</script>
