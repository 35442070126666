<template>
  <div class="atom-user-exposed-badge fill-height d-flex align-center" :style="disabled ? 'opacity: 0.75' : ''"
       :class="small ? 'small' : ''">
    <atom-button
      v-if="!noFab"
      :small="!small"
      :x-small="small"
      :class="small ? 'mr-1' : 'mr-4'"
      fab
      style="pointer-events: none"
      :dark="dark"
      :color="disabled ? '' : (dark ? 'cs-soft-white' : 'cs-green-forest')">
      <div class="text-no-wrap" :class="disabled ? '' : (dark ? 'text--cs-green-forest': 'white--text')">
        {{ value.initials }}
      </div>
    </atom-button>
    <div class="d-flex flex-column">
      <slot name="before-subtitle"></slot>
      <div class="text-no-wrap" :class="!small ? 'subtitle-1': ''">
        <span>{{ value.displayName }}</span>
      </div>
      <div class="email body-2 text-no-wrap" v-if="showEmail && value.email">
        <span>{{ value.email }}</span>
      </div>
      <slot name="after-subtitle"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.atom-user-exposed-badge {
  &.small {
    .email {
      font-size: 0.7rem !important;
    }
  }
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, toRefs } from 'vue'
import type { UserExposed } from 'app-model.carbon-saver'
import AtomButton from '../../../../components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'AtomUserExposedBadge',
  components: { AtomButton },
  props: {
    value: {
      type: Object as PropType<UserExposed>,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    noFab: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { value } = toRefs(props)

    const email = computed(() => {
      if ((value.value as UserExposed).email) {
        return (value.value as UserExposed).email
      }
      return undefined
    })

    return {
      email
    }
  }
})
</script>
