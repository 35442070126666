<template>
  <v-chip
    class="atom-chip"
    v-on="$listeners"
    v-bind="$attrs">
    <slot/>
    <div class="right-button-container">
      <slot name="right-button">
        <v-icon class="mr-n2 ml-2" small>mdi-pencil</v-icon>
      </slot>
    </div>
  </v-chip>
</template>
<style scoped lang="scss">
.atom-chip {
  position: static;
  font-weight: bold;

  .right-button-container {
    position: relative;
    right: -2px;
    width: 0;

    transition: width ease-in-out 200ms, right ease-in-out 200ms;
  }

  .v-chip:hover {
    .right-button-container {
      width: 24px;
      right: 0;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AtomChip'
})
</script>
