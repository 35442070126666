import config from '../config'
import { useAuthMock } from './auth.mock'
import { useAuthKeycloak } from './auth.keycloak'
import type { NavigationGuardNext, Route } from 'vue-router'
import type { AxiosOptions } from './axios'
import type { Ref } from 'vue'
import type { AppAbility } from 'app-model.carbon-saver'

export type UserPermissionScope = 'create' | 'read' | 'update' | 'delete' | 'disable' | 'export' | 'import'

export interface PermissionRequestResourceSpec {
  resource: string | string[]
  scopes: UserPermissionScope | UserPermissionScope[]
}

export type HasPermissionSpec = PermissionRequestResourceSpec | PermissionRequestResourceSpec[]

export interface UserPermissions {
  hasPermission: (resourceOrSpec: string | HasPermissionSpec, ...scopes: UserPermissionScope[]) => boolean
  hasPermissionRef: (resourceOrSpec: string | HasPermissionSpec, ...scopes: UserPermissionScope[]) => Ref<boolean>
}

export type User = UserData & UserDataReadOnly

export interface UserData {
  givenName: string
  familyName: string
  email: string
  language?: string | null
}

export interface UserDataReadOnly {
  licenses?: string[]
  subscriptions?: string[]
}

export interface UserInfoExt {
  newsletter: boolean
}

export interface UseAuth extends UserPermissions {
  initOnce: () => Promise<void>
  navigationGuard: (to: Route, from: Route, next: NavigationGuardNext) => Promise<void>
  axiosOptions: (options: AxiosOptions) => AxiosOptions
  user: Ref<User | null>
  ability: Ref<AppAbility | null>
  refresh: () => Promise<void>
  logout: () => Promise<void>
}

export const useAuth = config.keycloak.disabled === true ? useAuthMock : useAuthKeycloak
