<template>
  <v-container class="molecule-share-project-form">
    <v-row>
      <v-col>
        <h6 class="pb-2">
          {{ $tl('molecule-share-project-form.add-project-share-user', 'ajouter un membre') }}
        </h6>
        <v-autocomplete outlined
                        return-object
                        hide-details
                        :disabled="listOrganisationMembers.isLoading.value"
                        :loading="listOrganisationMembers.isLoading.value"
                        :item-disabled="false"
                        :item-value="itemValue"
                        :item-text="itemText"
                        :filter="itemFilter"
                        :placeholder="$tl('molecule-share-project-form.add-project-share-user-placeholder', 'choisissez un membre à ajouter')"
                        v-model="selectedOrganisationMember"
                        :items="availableOrganisationMembers">
          <template v-slot:selection="{ item }">
            <atom-organisation-member-badge class="flex-grow-1 pa-2"
                                            :value="item"
                                            show-email
                                            show-organisation-roles-chip>
            </atom-organisation-member-badge>
          </template>
          <template v-slot:item="{ item }">
            <atom-organisation-member-badge class="flex-grow-1 pa-2"
                                            :value="item"
                                            show-email
                                            show-organisation-roles-chip>
            </atom-organisation-member-badge>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h6 class="pb-2">
          {{ $tl('molecule-share-project-form.project-shares', 'Partages du projet') }}
        </h6>
        <v-list style="max-height: 350px"
                class="overflow-y-auto overflow-visible">
          <v-list-item v-for="(share, $index) of data.shares" :key="share.user.id" selectable class="px-0">
            <v-list-item-content>
              <div class="d-flex flex-grow-1 align-center">
                <atom-button text fab x-small @click="removeShareFromUser(share.user)" class="mr-2">
                  <v-icon>mdi-close</v-icon>
                </atom-button>
                <atom-project-share
                  class="flex-grow-1 px-2"
                  :value="share"
                  :guest="isUserGuest(share.user)"
                  @input="handleShareInput($event, $index)">
                </atom-project-share>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="data.shares.length === 0">
            <v-list-item-content>
              <span class="body-1">
                 {{ $tl('molecule-share-project-form.no-shares', 'aucun partage') }}
              </span>
            </v-list-item-content>
          </v-list-item>
          <template v-if="automatedShares.length > 0">
            <v-divider class="my-2 mb-4 mx-2"></v-divider>
            <v-subheader class="px-2 my-2">
              <span class="body-2 colons-after">
                {{
                  $tl('molecule-share-project-form.automated-shares-subheader', 'En plus des partages configurés, le projet est également accessible aux personnes suivantes')
                }}
              </span>
            </v-subheader>
            <v-list-item v-for="(share, $index) of automatedShares" :key="share.user.id" selectable class="px-0">
              <v-list-item-content class="py-0">
                <div class="d-flex flex-grow-1 align-center">
                  <atom-project-share
                    small
                    disabled
                    :author="share.author"
                    :administrator="share.administrator"
                    class="flex-grow-1 px-2"
                    :value="share"
                    @input="handleShareInput($event, $index)">
                  </atom-project-share>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.molecule-share-project-form {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { injectRequired } from '../../../../di'
import type {
  OrganisationMember,
  OrganisationMemberRole,
  Project,
  ProjectShare,
  UserExposed
} from 'app-model.carbon-saver'
import { CaslAbilityFactory, ProjectSubjectType } from 'app-model.carbon-saver'
import { useVModels } from '@vueuse/core'
import AtomButton from '../../../../components/atoms/AtomButton.vue'
import AtomProjectShare from '../atoms/AtomProjectShare.vue'
import { OrganisationState } from '../../../user/state/organisation'
import AtomOrganisationMemberBadge from '../../../user/components/atoms/AtomOrganisationMemberBadge.vue'

export interface ShareProjectFormData {
  shares: ProjectShare[]
}

export interface ProjectShareExt extends ProjectShare {
  automated?: boolean
  author?: boolean
  administrator?: boolean
}

export default defineComponent({
  name: 'MoleculeShareProjectForm',
  components: { AtomOrganisationMemberBadge, AtomProjectShare, AtomButton },
  props: {
    value: {
      type: Object as PropType<ShareProjectFormData>,
      default: () => ({ shares: [] })
    },
    project: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  setup: (props, { emit }) => {
    const { value: data } = useVModels(props, emit, { eventName: 'input' })

    const { organisationMembers, listOrganisationMembers } = injectRequired(OrganisationState).use
    listOrganisationMembers.runOnce()

    const sharedUserIds = computed<Set<string>>(() => {
      const userIds: Set<string> = new Set()

      for (const share of mergedShares.value) {
        userIds.add(share.user.id)
      }

      return userIds
    })

    const caslAbilityFactory = new CaslAbilityFactory()

    const automatedShares = computed<ProjectShareExt[]>(() => {
      const automatedShares: ProjectShareExt[] = []

      const projectSubjectType = new ProjectSubjectType({
        authorId: props.project.createdBy?.id,
        shares: [],
        organisationId: props.project.organisationId
      })

      for (const organisationMember of organisationMembers.value) {
        const casl = caslAbilityFactory.forUser({
          id: organisationMember.id,
          roles: [],
          organisations: [organisationMember.organisationMembership]
        })

        const projectShareBase: ProjectShareExt = {
          user: organisationMember,
          author: organisationMember.id === projectSubjectType.authorId,
          administrator: organisationMember.organisationMembership.roles.includes('administrator'),
          automated: true
        }

        if (casl.can('share', projectSubjectType)) {
          automatedShares.push({ ...projectShareBase, role: 'administrator' })
        } else if (casl.can('update', projectSubjectType)) {
          automatedShares.push({ ...projectShareBase, role: 'contributor' })
        } else if (casl.can('read', projectSubjectType)) {
          automatedShares.push({ ...projectShareBase, role: 'guest' })
        }
      }

      return automatedShares.sort((a, b) => {
        if (a.author && !b.author) {
          return -1
        }
        return 0
      })
    })

    const availableOrganisationMembers = computed(() => {
      return organisationMembers.value.filter((u) => !sharedUserIds.value.has(u.id))
    })

    const selectedOrganisationMember = ref<OrganisationMember | null>(null)
    watch(organisationMembers, () => {
      nextTick(() => {
        selectedOrganisationMember.value = null
      })
    })

    watch(selectedOrganisationMember, (user) => {
      if (user) {
        const role: OrganisationMemberRole = isUserGuest(user) ? 'guest' : 'contributor'
        data.value.shares.push({ user, role })
      }

      nextTick(() => {
        selectedOrganisationMember.value = null
      })
    })

    const isUserGuest = (user: OrganisationMember | UserExposed) => {
      let organisationMember: OrganisationMember | undefined
      if ((user as OrganisationMember).organisationMembership) {
        organisationMember = (user as OrganisationMember)
      } else {
        organisationMember = availableOrganisationMembers.value.find(organisationMember => organisationMember.id === user.id)
      }
      if (organisationMember === undefined) return true

      return !organisationMember.organisationMembership.roles.includes('contributor') && !organisationMember.organisationMembership.roles.includes('administrator')
    }

    const removeShareFromUser = (user: UserExposed) => {
      const index = data.value.shares.findIndex((share) => share.user.id === user.id)
      if (index > -1) {
        data.value.shares.splice(index, 1)
      }
      selectedOrganisationMember.value = null
    }

    const itemText = (user: OrganisationMember | null) => {
      return user?.displayName
    }

    const itemValue = (user: OrganisationMember | null) => {
      return user?.id
    }

    const itemFilter = (user: OrganisationMember, queryText: string): boolean => {
      const lowerQuery = queryText.toLowerCase()

      if (user.displayName?.toLowerCase().includes(lowerQuery)) {
        return true
      }

      return false
    }

    const handleShareInput = (share: ProjectShare, index: number) => {
      data.value.shares.splice(index, 1, share)
    }

    const mergedShares = computed<ProjectShareExt[]>(() => {
      return [...data.value.shares, ...automatedShares.value.map(s => ({ ...s, automated: true }))]
    })

    return {
      data,
      availableOrganisationMembers,
      listOrganisationMembers,
      removeShareFromUser,
      handleShareInput,
      itemText,
      itemValue,
      itemFilter,
      isUserGuest,
      selectedOrganisationMember,
      automatedShares,
      mergedShares
    }
  }
})
</script>
