import { injectable, injectRequired } from '../../../di'
import type { UseProjectPublicShare } from '../use/project-public-share'
import { useProjectPublicShare } from '../use/project-public-share'
import { ProjectPublicShareResource } from '../resources/project-public-share'

export class ProjectPublicShareState {
  public static injectable = injectable(ProjectPublicShareState)
  public readonly use: UseProjectPublicShare

  constructor () {
    this.use = useProjectPublicShare({
      resource: injectRequired(ProjectPublicShareResource),
      projectId: injectRequired('ref<projectId>')
    })
  }
}
