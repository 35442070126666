import type { ComputedRef, Ref } from 'vue'
import { computed } from 'vue'
import { humanizer } from 'humanize-duration'
import { tl } from '../../../plugins/i18next'
import { useI18n } from '../../../use/i18n'
import type { Project } from 'app-model.carbon-saver'

export interface UseProjectDisplay {
  renderedUpdatedAt: ComputedRef<string>
  inputCompletionLabel: ComputedRef<string | undefined>
  inputCompletionColor: ComputedRef<string | undefined>
}

export interface UseProjectDisplayOptions {
  project: Ref<Project>
}

export function useProjectDisplay (options: UseProjectDisplayOptions): UseProjectDisplay {
  const {
    project
  } = options

  const { currentLanguage } = useI18n()

  const humanize = computed(() => {
    return humanizer({ language: currentLanguage.value.code, round: true, largest: 1 })
  })

  const renderedUpdatedAt = computed(() => {
    const updatedAt = project.value.updatedAt

    const duration = updatedAt !== undefined ? new Date().getTime() - new Date(updatedAt).getTime() : undefined

    if (duration === undefined) {
      return tl('project-ux.updated-at.never', 'jamais')
    }

    if (duration < 60_000) {
      return tl('project-ux.updated-at.just-now', 'à l\'instant')
    }

    const humanized = humanize.value(duration)
    return tl('project-ux.updated-at.since-duration', {
      defaultValue: 'il y a {{ duration }}',
      duration: humanized
    })
  })

  const inputCompletionLabel = computed(() => {
    if (project.value.componentsStats === undefined) return undefined

    if (project.value.componentsStats.inputCompletion <= 0) {
      return tl('project-display.input-completion.pristine', 'vide')
    }

    if (project.value.componentsStats.inputCompletion >= 1) {
      return tl('project-display.input-completion.completed', 'obtenu')
    }

    return tl('project-display.input-completion.in-progress', 'à compléter')
  })

  const inputCompletionColor = computed(() => {
    if (project.value.componentsStats === undefined) return undefined

    if (project.value.componentsStats.inputCompletion <= 0) {
      return undefined
    }

    if (project.value.componentsStats.inputCompletion >= 1) {
      return 'primary'
    }

    return 'error'
  })

  return {
    renderedUpdatedAt,
    inputCompletionLabel,
    inputCompletionColor
  }
}
