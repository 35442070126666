import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ProjectComponentResource } from '../resources/project-component'
import { refDefault } from '@vueuse/core'
import type { Workflow } from 'engine.carbon-saver'

export interface UseProjectMetadata {
  projectMetadataOptional: Ref<Workflow | undefined>
  projectMetadata: Ref<Workflow>
  readProjectMetadata: UseAsyncRunner<Workflow>
  options: Required<UseProjectMetadataOptions>
}

export interface UseProjectMetadataOptions {
  resource: ProjectComponentResource
  projectId: Ref<string>
}

export function useProjectMetadata (options: UseProjectMetadataOptions): UseProjectMetadata {
  const { projectId } = options

  const resource = options.resource
  const projectMetadataOptional = ref<Workflow>()
  const projectMetadata = refDefault(projectMetadataOptional, {
    steps: [],
    rootComponent: {
      id: '',
      model: {
        id: ''
      }
    }
  })

  const readProjectMetadata = useAsyncRunner(async () => await resource.readProjectMetadata(projectId.value), projectMetadataOptional)

  return {
    projectMetadataOptional,
    projectMetadata,
    readProjectMetadata,
    options: {
      resource,
      projectId
    }
  }
}
