import { useLocalStorage } from '@vueuse/core'
import type { Route } from 'vue-router'

const debug = useLocalStorage('debug', false)

export function useDebug () {
  return {
    handleRouteDebug: (route: Route) => {
      if (route.query._debug_ === 'true') {
        debug.value = true
      } else if (route.query._debug_ === 'false') {
        debug.value = false
      }
    },
    debug
  }
}
